import { useCallback } from 'react';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import { useQueryClient } from 'react-query';
import { useTheme } from 'styled-components';

import { Button, Table, Badge } from 'components';
import { QUERIES } from '@constants';
import { onErrorMessage, onSuccessMessage } from 'helpers';
import { distanceToNow, sweetAlert } from 'utils';

import { useRemoveAssistantAgent } from 'services'

import type {
    AssistantAgentTableInterface,
} from './interface';

export const AssistantAgentTable = ({
    data,
    isLoading,
    onSelectRegister,
}: AssistantAgentTableInterface) => {
    const theme = useTheme();

    const { mutateAsync: removeAssistantAgentAsync } = useRemoveAssistantAgent();

    const queryClient = useQueryClient();

    const titleMessages = 'Agentes de IA';


    function onRemoveRegisterRequest(id: string) {
        sweetAlert
            .fire({
                title: 'Você tem certeza?',
                text: 'Se você confirmar este registro será removido e está ação não poderá ser revertida!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: theme.colors.base.primary,
                cancelButtonColor: theme.colors.base.danger,
                confirmButtonText: 'Sim, remover registro!',
                cancelButtonText: 'Não, cliquei errado!',
            })
            .then(async (result) => {
                if (result.isConfirmed) {

                    const response = await removeAssistantAgentAsync(id);

                    if (response.success) {
                        onSuccessMessage(
                            titleMessages,
                            'Registro removido com sucesso!'
                        );

                        queryClient.invalidateQueries(QUERIES.ASSISTANT_AGENT.module);

                    } else {
                        onErrorMessage(titleMessages, 'Erro ao remover registro!');
                    }
                }
            });
    }

    const assistantAgentStatus = useCallback(
        (props: { active: boolean }) => {
            if (props.active) {
                return <Badge variation="success" model='light'>Ativo</Badge>;
            }

            return <Badge variation="danger" model='light'>Inativo</Badge>;

        }, []);

    const assistantAgentOption = useCallback(
        ({ uuid }) => (
            <div>
                <Button
                    model="light"
                    variation="primary"
                    onClick={() => onSelectRegister(uuid)}
                    isSquare
                    style={{ marginLeft: theme.spacing[2] }}
                >
                    <AiOutlineEdit size={theme.fontSizes[5]} />
                </Button>

                <Button
                    model="base"
                    variation="danger"
                    onClick={() => onRemoveRegisterRequest(uuid)}
                    isSquare
                    style={{ marginLeft: theme.spacing[2] }}
                >
                    <AiOutlineDelete size={theme.fontSizes[5]} />
                </Button>
            </div>
        ),
        []
    );

    return (
        <div style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
            <Table
                isLoading={isLoading}
                columns={[
                    {
                        dataField: 'slug',
                        text: 'Slug',
                        sort: true,
                        style: { width: '20%' },
                    },
                    {
                        dataField: 'active',
                        text: 'Status',
                        style: { width: '10%' },
                        formatter: (value) => assistantAgentStatus({ active: value }),
                    },
                    {
                        dataField: 'prompt',
                        text: 'Prompts',
                        style: { width: '15%' },
                        formatter: (value) => `${(value?.length || 0)} prompts`,
                    },
                    {
                        dataField: 'createdAt',
                        text: 'Criado em',
                        style: { minWidth: '14rem' },
                        formatter: (value) => distanceToNow(value),
                        sort: true,
                    },
                    {
                        dataField: 'updatedAt',
                        text: 'Atualiado em',
                        style: { minWidth: '14rem' },
                        formatter: (value) => distanceToNow(value),
                        sort: true,
                    },
                    {
                        dataField: 'id',
                        text: 'Opções',
                        style: { minWidth: '10rem' },

                        formatter: (_value, _index, row) =>
                            assistantAgentOption(row),
                    },
                ]}
                data={data || []}
            />
        </div>
    );
};

