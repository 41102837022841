import { useEffect, useMemo, useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import * as Yup from 'yup';

import { Button, Drawer, InputText } from 'components';
import { onValidationSchemaWithErrors } from 'helpers';

import { PromptSummary } from './components'

import {
    AssistantAgentFieldWrap,
    AssistantAgentFieldLabel,
    AssistantAgentFieldMoreButton,
    AssistantAgentFieldList,
    AssistantAgentFieldForm,
    AssistantAgentFieldFormTitle,
    AssistantAgentFieldFormFooter,
} from './PromptField.style';

import type { IAAssistantAgentPrompt } from '@types';

import type {AssistantAgentFormPromptFieldProps  } from './PromptField.type';

export const AssistantAgentFormPromptField = ({
    items,
    isLoading,
    isOpen,
    onOpen,
    onSave,
}: AssistantAgentFormPromptFieldProps) => {
    const [editRegister, setEditRegister] = useState<
        Partial<IAAssistantAgentPrompt> | undefined
    >(undefined);
    const [editRegisterIndex, setEditRegisterIndex] = useState<number | undefined>(undefined);
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [listenErrors, setListenErrors] = useState<boolean>(false);


    const onChangeValue = (
        key: keyof { content: string },
        value: string | number
    ) => {
        const rest: Partial<IAAssistantAgentPrompt> = {};

        const newValue = {
            ...editRegister,
            ...rest,
            [key]: value,
        } as {content: string };

        setEditRegister(newValue);
    };

    const onNewRegister = () => {
        onOpen(true);
        setEditRegister(undefined);
        setEditRegisterIndex(undefined);
    };

    const onEditRegister = (item: Partial<IAAssistantAgentPrompt>, index: number) => {
        setEditRegister(item);
        setEditRegisterIndex(index);
        onOpen(true);
    };

    const shapeSchema = {
        content: Yup.string().required("O nome é obrigatório."),
    };

    const onSubmitSave = async () => {
        const result = await onValidationSchemaWithErrors(
            shapeSchema,
            editRegister
        );

        if (!result.isValid) {
            setErrors(result.errors);
            setListenErrors(true);

            return;
        }

        if (editRegister) {

            if (editRegisterIndex !== undefined) {
                const newItems =
                    items?.map((item, index) =>
                        index === editRegisterIndex ? editRegister : item
                    ) || [];

                onSave(newItems);
            } else {
                onSave([...items, {id: `${items?.length}`, ...editRegister}]);
            }

            onOpen(false);
            setListenErrors(false);
        }
    };

    const onRemoveRegister = () => {
        if (editRegisterIndex !== undefined) {
            const newItems = items?.filter(
                (_, index) => index !== editRegisterIndex
            );

            onSave(newItems);
            onOpen(false);
            setListenErrors(false);
        }
    };

    useEffect(() => {
        const updateErrors = async () => {
            if (listenErrors) {
                const result = await onValidationSchemaWithErrors(
                    shapeSchema,
                    editRegister
                );

                setErrors(result.errors);
            }
        };

        updateErrors();
    }, [listenErrors, editRegister]);

    const list = useMemo(() => {
        if (isLoading) {
            return <PromptSummary isLoading onEdit={() => null} />;
        }

        return items?.map((item, index) => (
            <PromptSummary
                key={item.id}
                item={item}
                onEdit={() => onEditRegister(item, index)}
            />
        ));
    }, [items, isLoading]);

    return (
        <AssistantAgentFieldWrap>
            <AssistantAgentFieldLabel>
                Prompts
            </AssistantAgentFieldLabel>

            <AssistantAgentFieldList>{list}</AssistantAgentFieldList>

            <AssistantAgentFieldMoreButton onClick={onNewRegister}>
                <BiPlus /> Adicionar prompt
            </AssistantAgentFieldMoreButton>

            <Drawer
                isOpen={isOpen}
                onOpen={onOpen}
                position="absolute"
                showCloseButton={false}
            >
                <AssistantAgentFieldForm>
                    <AssistantAgentFieldFormTitle>
                        {editRegister
                            ? "Editar prompt"
                            : "Adicionar prompt"}
                    </AssistantAgentFieldFormTitle>
                    
                    <InputText
                        label="Conteúdo"
                        placeholder="Informe um conteúdo"
                        name="content"
                        isRequired
                        onChange={(newValue) =>
                            onChangeValue('content', newValue)
                        }
                        isTextArea
                        value={
                            editRegister?.content
                                ? editRegister.content
                                : ''
                        }
                        error={errors?.content}

                    />

                    <AssistantAgentFieldFormFooter>
                        {editRegister && (
                            <Button
                                variation="danger"
                                onClick={onRemoveRegister}
                                style={{ marginRight: 'auto' }}
                            >
                                Remover
                            </Button>
                        )}
                        <Button variation="light" onClick={() => onOpen(false)}>
                            Voltar
                        </Button>
                        <Button onClick={onSubmitSave}>
                           Avançar
                        </Button>
                    </AssistantAgentFieldFormFooter>
                </AssistantAgentFieldForm>
            </Drawer>
        </AssistantAgentFieldWrap>
    );
};
