import styled, { css } from 'styled-components';

import type { TooltipInterface } from './interface';

interface TooltipWrapInterface
    extends Pick<TooltipInterface, 'position' | 'startContent'> {}

const getPosition = ({ position, startContent = 0 }: TooltipWrapInterface) => {
    const positions = {
        top: css`
            span {
                margin-bottom: calc(
                    ${(props) => props.theme.spacing[4]} + ${startContent}rem
                );
                bottom: 100%;

                &:before {
                    border-top: ${(props) => props.theme.spacing[2]} solid
                        ${(props) => props.theme.colors.base.white};
                    border-right: ${(props) => props.theme.spacing[2]} solid
                        transparent;
                    border-left: ${(props) => props.theme.spacing[2]} solid
                        transparent;

                    left: 50%;
                    margin-left: -${(props) => props.theme.spacing[1]};
                    bottom: -${(props) => props.theme.spacing[2]};
                }
            }
        `,
        left: css`
            span {
                margin-right: calc(
                    ${(props) => props.theme.spacing[4]} + ${startContent}rem
                );
                right: 100%;

                &:before {
                    border-left: ${(props) => props.theme.spacing[2]} solid
                        ${(props) => props.theme.colors.base.white};
                    border-bottom: ${(props) => props.theme.spacing[2]} solid
                        transparent;
                    border-top: ${(props) => props.theme.spacing[2]} solid
                        transparent;

                    right: -${(props) => props.theme.spacing[2]};
                }
            }
        `,
        right: css`
            span {
                margin-left: calc(
                    ${(props) => props.theme.spacing[4]} + ${startContent}rem
                );
                left: 100%;

                &:before {
                    border-right: ${(props) => props.theme.spacing[2]} solid
                        ${(props) => props.theme.colors.base.white};
                    border-bottom: ${(props) => props.theme.spacing[2]} solid
                        transparent;
                    border-top: ${(props) => props.theme.spacing[2]} solid
                        transparent;

                    left: -${(props) => props.theme.spacing[2]};
                }
            }
        `,
        bottom: css`
            span {
                margin-top: calc(
                    ${(props) => props.theme.spacing[4]} + ${startContent}rem
                );
                top: 100%;

                &:before {
                    border-bottom: ${(props) => props.theme.spacing[2]} solid
                        ${(props) => props.theme.colors.base.white};
                    border-right: ${(props) => props.theme.spacing[2]} solid
                        transparent;
                    border-left: ${(props) => props.theme.spacing[2]} solid
                        transparent;

                    left: 50%;
                    margin-left: -${(props) => props.theme.spacing[1]};
                    top: -${(props) => props.theme.spacing[2]};
                }
            }
        `,
    };

    return positions[position || 'right'];
};

export const TooltipWrap = styled.div<TooltipWrapInterface>`
    position: relative;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    span {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0.3s, opacity 0.3s linear;

        position: absolute;

        background-color: ${(props) => props.theme.colors.base.white};
        border-radius: ${(props) => props.theme.borderRadius.default};
        box-shadow: 0px 0px ${(props) => props.theme.spacing[15]} 0px
            ${(props) => props.theme.colors.shadow.default};
        padding: ${(props) => props.theme.spacing[4]};
        white-space: nowrap;
        z-index: 107;

        &:before {
            content: '';
            position: absolute;
            display: block;
            width: 0;
            height: 0;
        }
    }

    &:hover {
        span {
            visibility: visible;
            opacity: 1;
        }
    }

    ${(props) => getPosition(props)}
`;
