import styled from 'styled-components';

export const ProfileWrap = styled.div`
    display: flex;
    align-items: center;
    height: 100%;

    .box-name {
        display: flex;
        align-items: center;
        justify-content: center;

        height: ${(props) => props.theme.spacing[12]};
        width: ${(props) => props.theme.spacing[12]};
        background-size: cover;
        background-position: center;
        border-radius: ${(props) => props.theme.borderRadius.default};

        background-color: ${(props) => props.theme.colors.light.primary};
        font-size: ${(props) => props.theme.fontSizes[8]};
        color: ${(props) => props.theme.colors.base.primary};
        font-weight: 400;
    }
`;
