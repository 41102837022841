/* eslint-disable */
import { format } from 'date-fns-tz';
import { formatDistance, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
/* eslint-enable */

export const convertDateENToBR = (value?: string | Date): string => {
    if (!value || value === undefined) {
        return '';
    }

    if (value instanceof Date) {
        return format(value, 'dd/MM/y', {
            timeZone: 'America/Sao_Paulo',
            locale: pt,
        });
    }

    return format(parseISO(value), 'dd/MM/y', {
        timeZone: 'America/Sao_Paulo',
        locale: pt,
    });
};

export const distanceToNow = (value: string, timezone: number = -3): string => {
    if (!value) {
        return 'Data inválida';
    }

    const dateValue = parseISO(value);
    const now = new Date();
    
    const utcDate = new Date(Date.UTC(
        dateValue.getUTCFullYear(),
        dateValue.getUTCMonth(),
        dateValue.getUTCDate(),
        dateValue.getUTCHours(),
        dateValue.getUTCMinutes(),
        dateValue.getUTCSeconds()
    ));
    
    utcDate.setHours(utcDate.getHours() + timezone);

    const utcNow = new Date(Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds()
    ));

    return formatDistance(utcDate, utcNow, {
        addSuffix: true,
        locale: pt,
    });
};
