import styled from "styled-components";

export const ButtonsWrap = styled.div`
    display: flex;
    align-items: center;
    gap: ${props => props.theme.spacing[3]};
`;

export const FormWrap = styled.form`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing[8]};
    padding: ${(props) => props.theme.spacing[8]} ${(props) => props.theme.spacing[9]};
`;