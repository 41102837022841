import { useCallback } from 'react';

import { Badge, Table } from 'components';

import { distanceToNow, maskCellphone } from 'utils';
import { useGetGatewayPaymentCustomerList } from 'services';

import { TableWrap, UserInfoWrap } from './styles'

export const GatewayPaymentCustomerTable = () => {

    const { data, isLoading } = useGetGatewayPaymentCustomerList()

    const userInfo = useCallback(
        ({ email, name }) => (
            <UserInfoWrap>
                <span className="name">
                    {name || 'Não informado'}
                </span>
                <span className="email">{email}</span>
            </UserInfoWrap>
        ),
        []
    );

    const userHasCard = useCallback(
        (defaultCard) =>
            defaultCard ? <Badge variation='primary'>Sim</Badge> : <Badge variation='light'>Não</Badge>,
        []
    );

    return (
        <TableWrap>
            <Table
                isLoading={isLoading}
                columns={[
                    {
                        dataField: 'firstName',
                        text: 'Informação',
                        sort: true,
                        formatter: (_value, _index, row) => userInfo(row)
                    },
                    {
                        dataField: 'phone',
                        text: 'Telefone',
                        style: { minWidth: '12.5rem' },
                        formatter: (value) => value ? maskCellphone(value) : "-",
                    },
                    {
                        dataField: 'defaultCard',
                        text: 'Possui Cartão',
                        sort: true,
                        formatter: (value) => userHasCard(value),
                    },
                    {
                        dataField: 'dateCreated',
                        text: 'Criado',
                        style: { minWidth: '12.5rem' },
                        formatter: (value) => distanceToNow(value),
                        sort: true,
                    },
                  
                ]}
                data={data?.data || []}
            />
        </TableWrap>
    );
};
