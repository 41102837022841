import styled from 'styled-components';

export const InputTextWrap = styled.div`
    width: 100%;

    button {
        position: absolute;
        top: 50%;
        margin-top: -${(props) => props.theme.spacing[3]};

        right: ${(props) => props.theme.spacing[3]};
        height: ${(props) => props.theme.fontSizes[6]};

        svg {
            font-size: ${(props) => props.theme.fontSizes[6]};
            color: ${(props) => props.theme.colors.text.primary};
        }
    }
`;

export const RemoveButton = styled.button.attrs({ type: 'button' })`
    right: ${(props) => props.theme.spacing[3]};
    height: ${(props) => props.theme.fontSizes[6]};

    svg {
        font-size: ${(props) => props.theme.fontSizes[6]};
        color: ${(props) => props.theme.colors.text.primary};
    }
`;

export const AddButton = styled.button.attrs({ type: 'button' })`
    width: 100%;
    padding: ${(props) => props.theme.spacing[3]};
    background-color: ${(props) => props.theme.colors.active.primary};
    color: ${(props) => props.theme.colors.text.primary};
    font-size: ${(props) => props.theme.fontSizes[6]};
    border: none;
    border-radius: ${(props) => props.theme.borderRadius.default};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${(props) => props.theme.spacing[3]};
`;
