import api from './api';

import type { IAAccountTag } from '@types';

export const ACCOUNT_TAG_URL = 'accounts_tags';

export const getAccountsTagsList = async () => {
    const { data } = await api.get(ACCOUNT_TAG_URL);

    return data;
};

export const getAccountsTagsDetail = async (id: string) => {
    const { data } = await api.get(`${ACCOUNT_TAG_URL}/${id}`);

    return data;
};

export const createAccountsTags = async (payload: Partial<IAAccountTag>) => {
    const { data } = await api.post(ACCOUNT_TAG_URL, payload);

    return data;
};

export const updateAccountsTags = async (
    id: number | string,
    payload: Partial<IAAccountTag>
) => {
    const url = `${ACCOUNT_TAG_URL}/${id}`;

    const { data } = await api.put(url, payload);

    return data;
};

export const removeAccountsTags = async (id: number | string) => {
    const url = `${ACCOUNT_TAG_URL}/${id}`;

    const { data } = await api.delete(url);

    return data;
};
