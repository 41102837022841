import styled from 'styled-components';

export const TabsWrap = styled.ul`
    display: inline-flex;
    flex-wrap: wrap;

    li {
        margin-top: ${(props) => props.theme.spacing[2]};
        margin-right: ${(props) => props.theme.spacing[10]};

        button {
            display: inline-block;
            padding: ${(props) => props.theme.spacing[5]} 0;
            border-bottom-width: ${(props) =>
                props.theme.borderSize.extraLarge};

            font-weight: 700;
            font-size: ${(props) => props.theme.fontSizes[5]};
            color: ${(props) => props.theme.colors.base.gray500};

            &:hover {
                border-color: ${(props) => props.theme.colors.base.secondary};
            }

            &.active {
                color: ${(props) => props.theme.colors.base.secondary};
                border-color: ${(props) => props.theme.colors.base.secondary};
            }
        }
    }
`;
