import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { useMutation, useQuery } from 'react-query';
import { AiOutlineNotification } from 'react-icons/ai';

import { QUERIES, ROUTES_PANEL } from '@constants';
import { Button, Card, Grid, InputText, Modal } from 'components';
import { useLayout } from 'contexts/LayoutContext';
import { onErrorMessage, onSuccessMessage } from 'helpers';
import { getAccountsList, sendAllPushNotification } from 'services';

import AccountTable from '../components/Table';

const AccountsList = () => {
    const theme = useTheme();

    const { initLayout } = useLayout();

    useEffect(() => {
        initLayout('Lista de Contas', [
            {
                label: 'Agtor Admin',
                link: ROUTES_PANEL.fullPath,
            },

            {
                label: 'Contas',
                link: ROUTES_PANEL.ACCOUNTS.fullPath,
            },
        ]);
    }, []);

    const { data, isLoading } = useQuery(QUERIES.ACCOUNT.module, () =>
        getAccountsList()
    );

    const [titleNotification, setTitleNotification] = useState('');
    const [textNotification, setTextNotification] = useState('');
    const [isOpenModal, setIsOpenModal] = useState(false);

    const handleOpenModalNotification = () => {
        setIsOpenModal(!isOpenModal);
    };

    const { mutate: sendPushNotification, isLoading: isLoadingNotification } =
        useMutation(
            () => sendAllPushNotification(titleNotification, textNotification),
            {
                mutationKey: [QUERIES.PUSH_NOTIFICATION.send],
                onSuccess: (response) => {
                    onSuccessMessage('Notificações', response.message);

                    setTitleNotification('');
                    setTextNotification('');
                    setIsOpenModal(false);
                },
                onError: (err) => onErrorMessage('Notificações', err),
            }
        );

    return (
        <Grid style={{ marginTop: theme.spacing[6] }}>
            <Card
                headerTitle="Lista de Usuários"
                headerSubTitle="Todos os registros da base"
                headerToolbar={
                    <Button
                        model="light"
                        variation="danger"
                        onClick={handleOpenModalNotification}
                    >
                        Notificação Geral{' '}
                        <AiOutlineNotification
                            style={{ marginLeft: theme.spacing[2] }}
                        />
                    </Button>
                }
            >
                <AccountTable data={data} isLoading={isLoading} />
            </Card>

            <Modal
                title="Nova Notificação"
                subTitle="Será enviada para todos usuários"
                isOpen={isOpenModal}
                onCloseModal={() => setIsOpenModal(false)}
                footer={
                    <Button
                        isLoading={isLoadingNotification}
                        onClick={sendPushNotification}
                    >
                        Enviar
                    </Button>
                }
            >
                <InputText
                    label="Título da Notificação"
                    name="title-notification"
                    onChange={setTitleNotification}
                    value={titleNotification}
                    placeholder="Informe o título da notificação!"
                    style={{ marginBottom: theme.spacing[8] }}
                    isRequired
                />

                <InputText
                    label="Texto da Notificação"
                    name="text-notification"
                    onChange={setTextNotification}
                    value={textNotification}
                    placeholder="Informe o texto da notificação!"
                    isRequired
                />
            </Modal>
        </Grid>
    );
};

export default AccountsList;
