import { useCallback } from 'react'
import { Badge, Card, Grid, Table } from 'components';
import { distanceToNow, convertFloatToBR } from 'utils';

import type {
    CardAccountAccessInterface,
} from './interface';

const CardPayments = ({ payments }: CardAccountAccessInterface) => {
    

    const statusColumn = useCallback(
        (value) => (
            <Badge variation="primary" model="light">{value}</Badge>
        ),
        []
    );

    return (
    <Grid>
        <Card
            headerTitle="Pagamentos"
            headerSubTitle="Feitos por este usuário"
        >
            <div
                style={{ width: '100%', height: '100%', overflowY: 'auto' }}
            >
                <Table
                    columns={[
                        {
                            dataField: 'paymentMethod',
                            text: 'Método de Pagamento',
                            style: {
                                width: '100%',
                            },
                        },
                        {
                            dataField: 'status',
                            text: 'Status',
                            style: { minWidth: '15rem' },
                            formatter: (value) => statusColumn(value),
                        },
                        {
                            dataField: 'value',
                            text: 'Valor',
                            style: { minWidth: '15rem' },
                            formatter: (value) => convertFloatToBR(value, 2, "R$ "),
                        },
                        {
                            dataField: 'createdAt',
                            text: 'Criado em',
                            style: { minWidth: '15rem' },
                            formatter: (value) => distanceToNow(value),
                            sort: true,
                        },
                        {
                            dataField: 'updatedAt',
                            text: 'Atualiado em',
                            style: { minWidth: '14rem' },
                            formatter: (value) => distanceToNow(value),
                            sort: true,
                        },

                    ]}
                    data={payments}
                />
            </div>
        </Card>

    </Grid>
)};

export default CardPayments;
