const AUTH_BASE = `/auth/`;
const AUTH_SIGN_IN = `sign-in`;

const PANEL_BASE = `/panel/`;
const PANEL_DASHBOARD = `dashboard`;
const PANEL_ACCOUNTS = `accounts`;
const PANEL_ACCOUNTS_FORM = `form`;
const PANEL_ACCOUNTS_TAGS = `accounts_tags`;
const PANEL_ACCOUNTS_TAGS_FORM = `form`;
const PANEL_TRACKINGS = `trackings`;
const PANEL_PLANS = `plans`;
const GATEWAY_PAYMENTS = `gateway_payments`;

const PANEL_ASSISTANT_AGENTS = `assistant-agents`;

export const ROUTES_AUTH = {
    path: AUTH_BASE,
    fullPath: AUTH_BASE,
    SIGN_IN: {
        path: AUTH_SIGN_IN,
        fullPath: `${AUTH_BASE}${AUTH_SIGN_IN}`,
    },
};

export const ROUTES_PANEL = {
    path: PANEL_BASE,
    fullPath: PANEL_BASE,
    DASHBOARD: {
        path: PANEL_DASHBOARD,
        fullPath: `${PANEL_BASE}${PANEL_DASHBOARD}`,
    },
    ACCOUNTS: {
        path: PANEL_ACCOUNTS,
        fullPath: `${PANEL_BASE}${PANEL_ACCOUNTS}`,
        FORM: {
            path: `${PANEL_ACCOUNTS}/${PANEL_ACCOUNTS_FORM}`,
            fullPath: `${PANEL_BASE}${PANEL_ACCOUNTS}/${PANEL_ACCOUNTS_FORM}`,
        },
    },
    ACCOUNTS_TAGS: {
        path: PANEL_ACCOUNTS_TAGS,
        fullPath: `${PANEL_BASE}${PANEL_ACCOUNTS_TAGS}`,
        FORM: {
            path: `${PANEL_ACCOUNTS_TAGS}/${PANEL_ACCOUNTS_TAGS_FORM}`,
            fullPath: `${PANEL_BASE}${PANEL_ACCOUNTS_TAGS}/${PANEL_ACCOUNTS_TAGS_FORM}`,
        },
    },
    ASSISTANT_AGENTS: {
        path: PANEL_ASSISTANT_AGENTS,
        fullPath: `${PANEL_BASE}${PANEL_ASSISTANT_AGENTS}`,
    },
    TRACKINGS: {
        path: PANEL_TRACKINGS,
        fullPath: `${PANEL_BASE}${PANEL_TRACKINGS}`,
    },
    PLANS: {
        path: PANEL_PLANS,
        fullPath: `${PANEL_BASE}${PANEL_PLANS}`,
    },
    GATEWAY_PAYMENTS: {
        path: GATEWAY_PAYMENTS,
        fullPath: `${PANEL_BASE}${GATEWAY_PAYMENTS}`,
    },
};
