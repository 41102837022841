import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { useQuery } from 'react-query';

import { Button, Card, Grid } from 'components';
import { useLayout } from 'contexts/LayoutContext';
import { QUERIES, ROUTES_PANEL } from '@constants';
import { getAccountsTagsList } from 'services';

import AccountsTagsForm from '../components/Form';
import AccountsTagsTable from '../components/Table';

const AccountsTagsList = () => {
    const theme = useTheme();

    const { initLayout } = useLayout();

    useEffect(() => {
        initLayout('Lista de Tags', [
            {
                label: 'Agtor Admin',
                link: ROUTES_PANEL.fullPath,
            },
            {
                label: 'Tags',
                link: ROUTES_PANEL.ACCOUNTS_TAGS.fullPath,
            },
        ]);
    }, []);

    const [isOpenModalRegister, setIsOpenModalRegister] = useState(false);
    const [idRegister, setIdRegister] = useState('');

    const onNewRegister = () => {
        setIdRegister('');
        setIsOpenModalRegister(true);
    };

    const onSelectRegister = (id: string) => {
        setIdRegister(id);
        setIsOpenModalRegister(true);
    };

    const { data, isLoading } = useQuery(QUERIES.ACCOUNT_TAG.list, () =>
        getAccountsTagsList()
    );

    return (
        <>
            <Grid style={{ marginTop: theme.spacing[6] }}>
                <Card
                    headerTitle="Lista de Tags de Usuários"
                    headerSubTitle="Todos os registros da base"
                    headerToolbar={
                        <Button onClick={onNewRegister}>Novo Registro</Button>
                    }
                >
                    <AccountsTagsTable
                        data={data}
                        isLoading={isLoading}
                        onSelectRegister={onSelectRegister}
                    />
                </Card>
            </Grid>

            <AccountsTagsForm
                id={idRegister}
                onOpen={setIsOpenModalRegister}
                isOpen={isOpenModalRegister}
            />
        </>
    );
};

export default AccountsTagsList;
