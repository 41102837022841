import { useQuery } from 'react-query';

import { QUERIES } from '@constants'
import api from './api';

import type { IAGatewayPaymentPaymentProps, IAGatewayPaymentCustomerProps } from '@types'

export const GATEWAY_PAYMENT_PAYMENT_URL = '/gateway_payments/payments';
export const GATEWAY_PAYMENT_CUSTOMER_URL = '/gateway_payments/customers';

export const useGetGatewayPaymentPaymentList = () => {
    const getGatewayPaymentPaymentList = async (): Promise<{
        success: boolean
        message: string
        data?: IAGatewayPaymentPaymentProps[]
    }> => {

        try {
            const { data } = await api.get(GATEWAY_PAYMENT_PAYMENT_URL);

            return {
                success: true,
                message: 'Registros encontrados com sucesso!',
                data: data?.data,
            };
        } catch (error) {
            return {
                success: false,
                message: 'Erro ao buscar registros!',
            };
        }
    };

    return useQuery({
        queryKey: [
            QUERIES.GATEWAY_PAYMENT.payment
        ],
        queryFn: () => getGatewayPaymentPaymentList(),
    });
};

export const useGetGatewayPaymentCustomerList = () => {
    const getGatewayPaymentCustomerList = async (): Promise<{
        success: boolean
        message: string
        data?: IAGatewayPaymentCustomerProps[]
    }> => {

        try {
            const { data } = await api.get(GATEWAY_PAYMENT_CUSTOMER_URL);

            return {
                success: true,
                message: 'Registros encontrados com sucesso!',
                data: data?.data,
            };
        } catch (error) {
            return {
                success: false,
                message: 'Erro ao buscar registros!',
            };
        }
    };

    return useQuery({
        queryKey: [
            QUERIES.GATEWAY_PAYMENT.customer
        ],
        queryFn: () => getGatewayPaymentCustomerList(),
    });
};

