import apiNoAuth from './apiNoAuth';

export const postSignIn = async (payload: {
    login: string;
    password: string;
}): Promise<any> => {
    const { data } = await apiNoAuth.post(`sessions`, payload);

    return data;
};
