import { useCallback, useMemo, useState } from 'react';
import {
    AiFillCaretLeft,
    AiFillCaretRight,
} from 'react-icons/ai';

import { MySearch, Table, List } from './components';

import { TableComponentWrap } from './styles';

import type { TableComponentInterface } from './interface';

const TableComponent = ({
    columns,
    data = [],
    isLoading,
    style,
}: TableComponentInterface) => {
    const [textFilter, setTextFilter] = useState('');
    const [sortObject, setSortObject] = useState({
        key: '',
        order: 'ASC',
    });

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [pageActive, setPageActive] = useState(0);

    const widthWindow = window.innerWidth;

    const applyFilter = (dataParam: Record<string, string>[]) => {
        if (textFilter === '') {
            return dataParam?.filter((item) => item !== null);
        }

        const textFilterLower = textFilter.toLowerCase();

        return dataParam?.filter((item) => {
            const resSome = Object.keys(item).some(
                (item2) =>
                    String(item[item2]).toLowerCase().indexOf(textFilterLower) >
                    -1
            );

            return !!resSome;
        });
    };

    const applySorter = (dataParam: Record<string, any>[]) => {
        if (sortObject.key === '') {
            return dataParam;
        }

        return dataParam.sort((item1, item2) => {
            let value1 = item1[sortObject.key];
            let value2 = item2[sortObject.key];

            // Tentar converter strings para números, se aplicável
            // eslint-disable-next-line
            if (typeof value1 === 'string' && !isNaN(Number(value1))) {
                value1 = Number(value1);
            }
            // eslint-disable-next-line
            if (typeof value2 === 'string' && !isNaN(Number(value2))) {
                value2 = Number(value2);
            }

            // Tentar converter strings para datas, se aplicável
            const date1 = new Date(value1);
            const date2 = new Date(value2);
            if (date1.toString() !== 'Invalid Date') {
                value1 = date1;
            }
            if (date2.toString() !== 'Invalid Date') {
                value2 = date2;
            }

            // Converter para lowercase se for string
            if (typeof value1 === 'string') {
                value1 = value1.toLowerCase();
            }
            if (typeof value2 === 'string') {
                value2 = value2.toLowerCase();
            }

            const { order } = sortObject;

            // Verificação para valores nulos ou undefined
            if (value1 == null) return order === 'ASC' ? 1 : -1; // eslint-disable-line
            if (value2 == null) return order === 'ASC' ? -1 : 1; // eslint-disable-line

            // Comparação
            if (order === 'ASC') {
                return value1 < value2 ? -1 : value1 > value2 ? 1 : 0; // eslint-disable-line
            }
            return value1 > value2 ? -1 : value1 < value2 ? 1 : 0; // eslint-disable-line
        });
    };

    const applyPaginate = (dataParam: Record<string, string>[]) => {
        const initialPosition = pageActive * itemsPerPage;

        return dataParam?.slice(
            initialPosition,
            initialPosition + itemsPerPage
        );
    };

    const getData = useCallback(() => {
        if (isLoading) {
            return [
                { label: '1' },
                { label: '2' },
                { label: '3' },
                { label: '4' },
                { label: '5' },
                { label: '6' },
                { label: '7' },
                { label: '8' },
                { label: '9' },
                { label: '10' },
            ];
        }

        if (!Array.isArray(data)) {
            return [];
        }

        const dataFilter = applyFilter(data);

        const dataSorter = applySorter(dataFilter);

        const dataPaginate = applyPaginate(dataSorter);

        return dataPaginate;
    }, [data, itemsPerPage, pageActive, sortObject, textFilter]);

    const totalItems = useMemo(
        () => applyFilter(data)?.length || 0,
        [data, textFilter]
    );

    const handleChangeItemPerPage = (valueParam: number) => {
        setItemsPerPage(valueParam);
        setPageActive(0);
    };

    return (
        <TableComponentWrap style={style}>
            <MySearch onSearch={setTextFilter} />

            {widthWindow > 700 ? (
                <Table
                    columns={columns}
                    data={getData()}
                    isLoading={isLoading}
                    onSetSortObject={setSortObject}
                    sortObject={sortObject}
                />
            ) : (
                <List
                    columns={columns}
                    data={getData()}
                    isLoading={isLoading}
                    onSetSortObject={setSortObject}
                    sortObject={sortObject}
                />
            )}

            <div className="footer">
                <div className="box-left">
                    <ul className="pagination">
                        {pageActive > 0 && (
                            <li className="page-item">
                                <button
                                    type="button"
                                    onClick={() =>
                                        setPageActive(
                                            (prevState) => prevState - 1
                                        )
                                    }
                                >
                                    <AiFillCaretLeft />
                                </button>
                            </li>
                        )}

                        {pageActive - 1 > 0 && (
                            <li className="page-item">
                                <button
                                    type="button"
                                    onClick={() =>
                                        setPageActive(
                                            (prevState) => prevState - 2
                                        )
                                    }
                                >
                                    {pageActive - 1}
                                </button>
                            </li>
                        )}

                        {pageActive > 0 && (
                            <li className="page-item">
                                <button
                                    type="button"
                                    onClick={() =>
                                        setPageActive(
                                            (prevState) => prevState - 1
                                        )
                                    }
                                >
                                    {pageActive}
                                </button>
                            </li>
                        )}

                        <li className="page-item active">
                            <button type="button" onClick={() => null}>
                                {pageActive + 1}
                            </button>
                        </li>

                        {pageActive * itemsPerPage + itemsPerPage <
                            totalItems && (
                            <li className="page-item">
                                <button
                                    type="button"
                                    onClick={() =>
                                        setPageActive(
                                            (prevState) => prevState + 1
                                        )
                                    }
                                >
                                    {pageActive + 2}
                                </button>
                            </li>
                        )}

                        {(pageActive + 1) * itemsPerPage + itemsPerPage <
                            totalItems && (
                            <li className="page-item">
                                <button
                                    type="button"
                                    onClick={() =>
                                        setPageActive(
                                            (prevState) => prevState + 2
                                        )
                                    }
                                >
                                    {pageActive + 3}
                                </button>
                            </li>
                        )}

                        {pageActive * itemsPerPage + itemsPerPage <
                            totalItems && (
                            <li className="page-item">
                                <button
                                    type="button"
                                    onClick={() =>
                                        setPageActive(
                                            (prevState) => prevState + 1
                                        )
                                    }
                                >
                                    <AiFillCaretRight />
                                </button>
                            </li>
                        )}
                    </ul>
                </div>
                <div className="box-right">
                    <span className="description">
                        Mostrando {pageActive * itemsPerPage + 1} até{' '}
                        {pageActive * itemsPerPage + itemsPerPage} de{' '}
                        {totalItems} registros.
                    </span>

                    <select
                        value={itemsPerPage}
                        onChange={(event) =>
                            handleChangeItemPerPage(Number(event.target.value))
                        }
                    >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={totalItems}>Todos</option>
                    </select>
                </div>
            </div>
        </TableComponentWrap>
    );
};

export default TableComponent;
