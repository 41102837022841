import { checkEmpty } from 'utils';

import { SelectWrap } from './styles';
import InputCapsule from '../InputCapsule';

import type { SelectInterface } from './interface';

const Select = ({
    id,
    label,
    isRequired,
    isLoading,
    name,
    placeholder,
    value,
    onChange = () => null,
    options,
    variation = 'solid',
}: SelectInterface) => (
    <SelectWrap className="select">
        <InputCapsule
            htmlFor={id}
            label={label}
            isLoading={isLoading}
            isRequired={isRequired}
            empty={checkEmpty(value)}
            variation={variation}
        >
            <select
                id={id || name}
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={(event) => onChange(event.target.value)}
            >
                {!checkEmpty(placeholder) && checkEmpty(value) && (
                    <option value="">{placeholder}</option>
                )}
                {Array.isArray(options) &&
                    options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
            </select>
        </InputCapsule>
    </SelectWrap>
);

export default Select;
