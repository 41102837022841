import api from './api';
import type { IAUser } from '@types';

export const USER_URL = 'users';

export const updateUsers = async (id: string, payload: Partial<IAUser>) => {
    const { data } = await api.put(`${USER_URL}/${id}`, payload);

    return data;
};
