import styled from 'styled-components'

export const TableWrap = styled.div`
    width: 100%; 
    height: 100%; 
    overflow-y: auto;
`

export const ColumnAmounts = styled.div`
    display: flex;
    flex-direction: column;

    strong {
        font-weight: 700;
        color: ${props => props.theme.colors.base.primary};
    }
`