import { useRoutes } from 'react-router-dom';

import { useAuth } from 'contexts/AuthContext';

import routes from './routes';

const Routes = () => {
    const { isSigned } = useAuth();

    const routing = useRoutes(routes(isSigned));

    return routing;
};

export default Routes;
