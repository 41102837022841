import { useCallback } from 'react';

import { Badge, Table } from 'components';
import { distanceToNow } from 'utils';

import type { TrackingsTableInterface } from './interface';

const TrackingTable = ({ data = [], isLoading }: TrackingsTableInterface) => {
    const columnApplication = useCallback(
        (value: string) => <Badge>{value}</Badge>,
        []
    );

    return (
        <div style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
            <Table
                isLoading={isLoading}
                columns={[
                    {
                        dataField: 'eventName',
                        text: 'Evento',
                    },
                    {
                        dataField: 'users',
                        text: 'Usuário',
                        formatter: (row) => row?.name,
                    },
                    {
                        dataField: 'application',
                        text: 'Aplicação',
                        formatter: (value) => columnApplication(value),
                    },
                    {
                        dataField: 'createdAt',
                        text: 'Criado em',
                        style: { minWidth: '14rem' },
                        formatter: (value) => distanceToNow(value),
                        sort: true,
                    },
                    {
                        dataField: 'updatedAt',
                        text: 'Atualiado em',
                        style: { minWidth: '14rem' },
                        formatter: (value) => distanceToNow(value),
                        sort: true,
                    },
                ]}
                data={data || []}
            />
        </div>
    );
};

export default TrackingTable;
