import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { ContentWrap, PanelWrap } from './styles';

import Header from './Header';
import Sidebar from './Sidebar';

const Panel = () => {
    const [isOpenResponsiveSidebar, setIsOpenResponsiveSidebar] =
        useState(false);
    const [isOpenResponsiveMenu, setIsOpenResponsiveMenu] = useState(false);

    const handleDisableMenus = () => {
        setIsOpenResponsiveSidebar(false);
        setIsOpenResponsiveMenu(false);
    };

    return (
        <PanelWrap>
            <Sidebar isOpenResponsiveSidebar={isOpenResponsiveSidebar} />

            <ContentWrap>
                <Header
                    isOpenResponsiveSidebar={isOpenResponsiveSidebar}
                    onOpenResponsiveSidebar={setIsOpenResponsiveSidebar}
                    isOpenResponsiveMenu={isOpenResponsiveMenu}
                    onOpenResponsiveMenu={setIsOpenResponsiveMenu}
                />
                <div className="content">
                    <Outlet />
                </div>
            </ContentWrap>

            {(isOpenResponsiveSidebar || isOpenResponsiveMenu) && (
                /* eslint-disable-next-line jsx-a11y/control-has-associated-label */
                <button
                    type="button"
                    className="responsive-overlay"
                    onClick={() => handleDisableMenus()}
                />
            )}
        </PanelWrap>
    );
};

export default Panel;
