import styled, { css } from 'styled-components';

import { VerticalStepperStatusEnum } from './interface'

export const VerticalStepperWrap = styled.div`
    display: flex;
    flex-direction: column;
`;


export const VerticalStepperLine = styled.div`
    display: block;
    flex-grow: 1;
    height: ${props => props.theme.spacing[12]};
    margin-left: calc(${props => props.theme.spacing[12]} / 2);
    border-left: 0.0625rem dashed ${props => props.theme.colors.base.gray300};
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
`

export const VerticalStepperItem = styled.div<{ status: keyof typeof VerticalStepperStatusEnum }>`
    display: flex;
    align-items: center;
    gap: ${props => props.theme.spacing[6]};
`;

const itemIndicatorStatusObjectStyles = {
    pending: css`
        background-color: ${props => props.theme.colors.light.primary};
        color: ${props => props.theme.colors.base.primary};
    `,
    active: css`
        background-color: ${props => props.theme.colors.base.primary};
        color: ${props => props.theme.colors.base.gray100};
    `,
    completed: css`
        background-color: ${props => props.theme.colors.light.primary};
        color: ${props => props.theme.colors.base.primary};
    `,
}

export const VerticalStepperItemIndicator = styled.div<{ status: keyof typeof VerticalStepperStatusEnum }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.theme.spacing[12]};
    height: ${props => props.theme.spacing[12]};
    border-radius: ${props => props.theme.borderRadius.large};

    font-size: ${props => props.theme.fontSizes[5]};

    font-weight: 700;

    ${props => itemIndicatorStatusObjectStyles[props.status]}
`;


const itemContentStatusObjectStyles = {
    completed: css`
        color: ${props => props.theme.colors.base.gray400};
    `,
    active: css`
        color: ${props => props.theme.colors.base.gray600};
    `,
    pending: css`
        color: ${props => props.theme.colors.base.primary};
    `,
}

export const VerticalStepperItemContent = styled.div<{ status: keyof typeof VerticalStepperStatusEnum }>`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing[2]};
    flex: 1;

    .title {
        font-size: ${props => props.theme.fontSizes[5]};
        font-weight: 700;
    }

    .description {
        font-size: ${props => props.theme.fontSizes[7]};
    }

    ${props => itemContentStatusObjectStyles[props.status]}
`;