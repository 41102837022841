import * as Yup from 'yup';
import { sweetAlert } from 'utils';

import { isAxiosError } from './isAxiosError';

export const onValidationSchema = async (
    shape: Record<string, Yup.AnySchema>,
    data: any,
    showSweetAlert?: boolean
) => {
    const response = {
        success: false,
        message: null,
    };
    const schema = Yup.object().shape(shape);

    await schema
        .validate(data)
        .then(() => {
            response.success = true;
            response.message = null;

            return response;
        })
        .catch((err: any) => {
            if (showSweetAlert === undefined || showSweetAlert) {
                sweetAlert.fire({
                    title: 'Cadastro!',
                    text: err.message,
                    icon: 'error',
                });
            }

            response.success = false;
            response.message = err.message;

            return response;
        });

    return response;
};

export const onValidationSchemaWithErrors = async (
    shape: Record<string, Yup.AnySchema>,
    data: unknown,
  ) => {
    const response = {
      isValid: false,
      errors: {},
    }
    const schema = Yup.object().shape(shape)
  
    await schema
      .validate(data, { abortEarly: false })
      .then(() => {
        response.isValid = true
        response.errors = {}
  
        return response
      })
      .catch((err) => {
        if (err instanceof Yup.ValidationError) {
          const errors = err.inner.reduce(
            (acc, error) => {
              if (error.path) {
                acc[error.path] = error.message
              }
              return acc
            },
            {} as Record<string, string>,
          )
  
          response.isValid = false
          response.errors = errors
        }
      })
  
    return response
  }
  

export const onSuccessMessage = (label: string, message: string) =>
    sweetAlert.fire({
        title: label,
        text: message,
        icon: 'success',
    });

export const onErrorMessage = (label: string, err: any) => {
    let message = err?.message || 'Falha ao remover registro!';

    if (isAxiosError(err) && err.response?.data?.message) {
        message = err.response?.data?.message;
    }

    sweetAlert.fire({
        title: label,
        text: message,
        icon: 'error',
    });
};
