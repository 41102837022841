import styled from 'styled-components';

import { responsivator } from 'helpers';

export const PanelWrap = styled.div`
    height: 100vh;
    width: 100vw;

    display: flex;
    flex-direction: row;

    .responsive-overlay {
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 300;

        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
`;

export const ContentWrap = styled.div`
    ${(props) =>
        responsivator([
            `width: calc(100vw - ${props.theme.spacing[28]});`,
            `width: calc(100vw - ${props.theme.spacing[28]});`,
            `width: calc(100vw - ${props.theme.spacing[28]});`,
            `width: 100vw;`,
        ])}

    ${(props) =>
        responsivator([
            `margin-left: ${props.theme.spacing[28]};`,
            `margin-left: ${props.theme.spacing[28]};`,
            `margin-left: ${props.theme.spacing[28]};`,
            `margin-left: 0;`,
        ])}

    overflow: hidden;
    

    .content {
        overflow-y: auto;
        height: calc(100vh - 6rem);

        margin-top: ${(props) => props.theme.spacing[24]};
        padding: ${(props) => props.theme.spacing[8]};
    }
`;
