import styled from 'styled-components';



export const UserInfoWrap = styled.div<{ color: string }>`
    display: flex;
    align-items: center;

    .photo {
        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;

        height: ${(props) => props.theme.spacing[14]};
        width: ${(props) => props.theme.spacing[14]};
        border-radius: ${(props) => props.theme.borderRadius.default};

        background-color: ${(props) =>  props.theme.colors.base.gray200};
        background-size: cover;
        background-position: center;

        margin-right: ${(props) => props.theme.spacing[5]};

        svg {
            font-size: ${(props) => props.theme.fontSizes[1]};
        }
        
        .premium-icon {
            display: flex;
            align-items: center;
            justify-content: center;

            position: absolute;
            top: -0.25rem;
            left: -0.25rem;
            transform: rotate(-40deg);

            height: 1.5rem;
            width: 1.5rem;
            border-radius: 50%;
            background-color: ${props => {
                if(props.color === 'success') {
                    return props.theme.colors.base.primary
                }

                if(props.color === 'warning') {
                    return props.theme.colors.base.secondary
                }

                if(props.color === 'danger') {
                    return props.theme.colors.base.danger
                }

                return props.theme.colors.base.gray300
            }};

            svg {
                font-size: ${(props) => props.theme.fontSizes[6]};
                color: ${(props) => {
                    if(props.color === 'success') {
                        return props.theme.colors.base.secondary
                    }

                    if(props.color === 'warning') {
                        return props.theme.colors.base.primary
                    }

                    if(props.color === 'danger') {
                        return props.theme.colors.base.white
                    }

                    return props.theme.colors.base.primary
                }};


            }

        }
    }

    .infos {
        display: flex;
        flex-direction: column;
        flex: 1;

        .name {
            font-size: ${(props) => props.theme.fontSizes[6]};
            color: ${(props) => props.theme.colors.text.dark};
            font-weight: 700;
        }

        .login {
            font-size: ${(props) => props.theme.fontSizes[7]};
            color: ${(props) => props.theme.colors.text.muted};
            font-weight: 400;
            word-wrap: break-word;
            overflow-wrap: break-word;
        }
    }
`;

export const IntegrationsWrap = styled.div``

export const TrackingWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${(props) => props.theme.spacing[2]};

`;
