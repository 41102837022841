import { useTheme } from 'styled-components';

import { CardInfoWrap } from './styles';

import Skeleton from '../../Skeleton';

import type { CardInfoInterface } from './interface';

const CardInfo = ({
    model,
    icon,
    isLoading = false,
    description,
    number,
    variation,
}: CardInfoInterface) => {
    const theme = useTheme();

    if (isLoading) {
        return (
            <CardInfoWrap variation={variation} model={model}>
                <div className="icon-card-info">
                    <Skeleton height="2.75rem" width="2.75rem" />
                </div>

                <h3 className="number">
                    <Skeleton height={theme.fontSizes[2]} width="4rem" />
                </h3>
                <h4 className="description">
                    <Skeleton height={theme.fontSizes.base} width="7rem" />
                </h4>
            </CardInfoWrap>
        );
    }

    return (
        <CardInfoWrap variation={variation} model={model}>
            <div className="icon-card-info">{icon}</div>

            <h3 className="number count-number">{number}</h3>
            <h4 className="description">{description}</h4>
        </CardInfoWrap>
    );
};

export default CardInfo;
