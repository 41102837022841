import api from './api';
import type { IAAccount } from '@types';

export const ACCOUNT_URL = 'accounts';

export const getAccountsList = async () => {
    const { data } = await api.get(ACCOUNT_URL);

    return data;
};

export const getAccountsDetail = async (id: number): Promise<IAAccount> => {
    const { data } = await api.get(`${ACCOUNT_URL}/${id}`);

    return data;
};

export const updateAccounts = async (id: number, payload: any) => {
    const { data } = await api.put(`${ACCOUNT_URL}/${id}`, payload);

    return data;
};

export const addTagsToAccounts = async (id: number, payload: any) => {
    const { data } = await api.post(`${ACCOUNT_URL}/${id}/tags`, payload);

    return data;
};

export const removeTagsToAccounts = async (
    id: number,
    accountsTagsIds: any
) => {
    const { data } = await api.delete(
        `${ACCOUNT_URL}/${id}/tags/${accountsTagsIds}`
    );

    return data;
};
