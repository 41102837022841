import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useTheme } from 'styled-components';

import { ReactComponent as AddUser } from 'assets/svgs/icons/communication/AddUser.svg';
import { ReactComponent as DeleteUser } from 'assets/svgs/icons/communication/DeleteUser.svg';
import { ReactComponent as Group } from 'assets/svgs/icons/communication/Group.svg';

import { QUERIES, ROUTES_PANEL } from '@constants';
import { LineChart, Card, CardInfo, Grid } from 'components';
import { getDashboardIndex } from 'services';
import { useLayout } from 'contexts/LayoutContext';

const Dashboard = () => {
    const theme = useTheme();

    const { initLayout } = useLayout();

    useEffect(() => {
        initLayout('Dashboard', [
            {
                label: 'Agtor Admin',
                link: ROUTES_PANEL.fullPath,
            },

            {
                label: 'Dashboard',
                link: ROUTES_PANEL.DASHBOARD.fullPath,
            },
        ]);
    }, []);

    const { data, isLoading } = useQuery(QUERIES.DASHBOARD.index, () =>
        getDashboardIndex()
    );

    const dataChartsUsers = useMemo(() => {
        if (
            !data?.charts?.newUsersGroupedByMonths ||
            !data?.charts?.neverLoggedInUsersGroupedByMonths
        ) {
            return [
                {
                    label: 'Usuários Novos',
                    backgroundColor: theme.colors.base.primary,
                    data: [
                        {
                            label: 'XX/XX',
                            value: 0,
                        },
                    ],
                },
                {
                    label: 'Usuários Ativos',
                    backgroundColor: theme.colors.base.info,
                    data: [
                        {
                            label: 'XX/XX',
                            value: 0,
                        },
                    ],
                },
                {
                    label: 'Usuários Que Nunca Logaram',
                    backgroundColor: theme.colors.base.danger,
                    data: [
                        {
                            label: 'XX/XX',
                            value: 0,
                        },
                    ],
                },
                {
                    label: 'Contas Pagas',
                    backgroundColor: theme.colors.base.secondary,
                    data: [
                        {
                            label: 'XX/XX',
                            value: 0
                        }
                    ]
                }
            ];
        }

        return [
            {
                label: 'Usuários Novos',
                backgroundColor: theme.colors.base.primary,
                borderColor: theme.colors.base.primary,
                data: data?.charts?.newUsersGroupedByMonths.map(
                    ({ count, period }: any) => ({
                        label: period,
                        value: count,
                    })
                ),
            },
            {
                label: 'Usuários Ativos',
                backgroundColor: theme.colors.base.info,
                borderColor: theme.colors.base.info,
                data: data?.charts?.usersGroupWithTrackingByMonths.map(
                    ({ count, period }: any) => ({
                        label: period,
                        value: count,
                    })
                ),
            },
            {
                label: 'Usuários Que Nunca Logaram',
                backgroundColor: theme.colors.base.danger,
                borderColor: theme.colors.base.danger,
                data: data?.charts?.neverLoggedInUsersGroupedByMonths.map(
                    ({ count, period }: any) => ({
                        label: period,
                        value: count,
                    })
                ),
            },
            {
                label: 'Contas pagas',
                backgroundColor: theme.colors.base.secondary,
                borderColor: theme.colors.base.secondary,
                data: data?.charts?.usersGroupWithTransactionByMonths.map(
                    ({ count, period }: any) => ({
                        label: period,
                        value: count,
                    })
                ),
            }
        ];
    }, [data, isLoading]);

    return (
        <div>
            <Grid numberColumns={[4, 4, 2, 2, 2, 1]}>
                <CardInfo
                    icon={<AddUser />}
                    number={`${data?.resume?.newUsers || 0} Usuários`}
                    description="novos nos últimos 7 dias"
                    isLoading={isLoading}
                />

                <CardInfo
                    icon={<Group />}
                    number={`${data?.resume?.actived7DaysUsers || 0} Usuários`}
                    description="ativos nos últimos 7 dias"
                    isLoading={isLoading}
                    variation="secondary"
                />

                <CardInfo
                    icon={<DeleteUser />}
                    number={`${data?.resume?.neverLoggedInUsers || 0} Usuários`}
                    description="nunca logaram no total"
                    model="light"
                    variation="danger"
                    isLoading={isLoading}
                />

                <CardInfo
                    icon={<Group />}
                    number={`${data?.resume?.allUsers || 0} Usuários`}
                    description="cadastrados no total"
                    model="light"
                    variation="primary"
                    isLoading={isLoading}
                />
            </Grid>

            <Grid numberColumns={1} style={{ marginTop: theme.spacing[6] }}>
                <Card
                    headerTitle="Gráfico de usuários"
                    headerSubTitle="Indicativos de usuários no último ano" 
                >
                    <LineChart height={300} data={dataChartsUsers} />
                </Card>
            </Grid>
        </div>
    );
};

export default Dashboard;
