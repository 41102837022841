import { Navigate } from 'react-router-dom';

import { AuthLayout, PanelLayout } from 'layouts';

import {
    AuthSignIn,
    PanelAccountsForm,
    PanelAccountsList,
    PanelAccountsTagsList,
    PanelDashboard,
    PanelTrackingsList,
    PanelPlansList,
    PanelGatewayPaymentsList,
    PanelAssistantAgentsList
} from 'pages';

import { ROUTES_AUTH, ROUTES_PANEL } from '@constants/routes';

const routes = (isSigned: boolean) => [
    {
        path: '/',
        element: <Navigate to={ROUTES_PANEL.DASHBOARD.fullPath} />,
    },
    {
        path: ROUTES_AUTH.path,
        element: <AuthLayout />,
        children: [
            {
                element: <AuthSignIn />,
                path: ROUTES_AUTH.SIGN_IN.path,
            },
        ],
    },
    {
        path: ROUTES_PANEL.path,
        element: isSigned ? (
            <PanelLayout />
        ) : (
            <Navigate to={ROUTES_AUTH.SIGN_IN.fullPath} />
        ),
        children: [
            {
                element: <PanelDashboard />,
                path: ROUTES_PANEL.DASHBOARD.path,
            },
            {
                element: <PanelAccountsList />,
                path: ROUTES_PANEL.ACCOUNTS.path,
            },
            {
                element: <PanelAccountsForm />,
                path: `${ROUTES_PANEL.ACCOUNTS.FORM.path}/:id`,
            },
            {
                element: <PanelAccountsTagsList />,
                path: ROUTES_PANEL.ACCOUNTS_TAGS.path,
            },
            {
                element: <PanelAssistantAgentsList />,
                path: ROUTES_PANEL.ASSISTANT_AGENTS.path,
            },
            {
                element: <PanelTrackingsList />,
                path: ROUTES_PANEL.TRACKINGS.path,
            },
            {
                element: <PanelPlansList />,
                path: ROUTES_PANEL.PLANS.path,
            },
            {
                element: <PanelGatewayPaymentsList />,
                path: ROUTES_PANEL.GATEWAY_PAYMENTS.path,
            }
        ],
    },
];

export default routes;
