import { FormEvent, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import * as Yup from 'yup';

import { QUERIES } from '@constants';
import { Button, Card, Drawer, InputNumber, InputText, Select } from 'components';
import { onValidationSchema, onErrorMessage, onSuccessMessage } from 'helpers';
import {
    useCreatePlan,
    useGetPlanDetail,
    useUpdatePlan
} from 'services';


import { PlanFormBenefitField} from './components'

import { ButtonsWrap, FormWrap } from './styles'
import type { IAPlan, IAPlanBenefit } from '@types';
import type { PlanFormInterface } from './interface';

export const PlanForm = ({
    id,
    isOpen,
    onOpen,
}: PlanFormInterface) => {
    const queryClient = useQueryClient();

    const titleMessages = 'Planos';

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [value, setValue] = useState<number>(0);
    const [status, setStatus] = useState('');
    const [benefits, setBenefits] = useState<Partial<IAPlanBenefit>[]>([]);

    const [isOpenDrawerBenefit, setIsOpenDrawerBenefit] = useState(false);


    const { data: dataGet, isLoading: isLoadingGet } = useGetPlanDetail(id)

    const { mutateAsync: createPlanAsync, isLoading: isLoadingCreate } = useCreatePlan();

    const { mutateAsync: updatePlanAsync, isLoading: isLoadingUpdate } = useUpdatePlan(id);

    const onHandleClose = () => {
        onOpen(false);
        setIsOpenDrawerBenefit(false);
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();

        const data: Partial<IAPlan> = {
            name,
            description,
            value,
            status,
            benefits: benefits?.map((item) => ({ name: item.name })),
        };

        const shapeSchema = {
            status: Yup.string().nullable().required('O status é obrigatório.'),
            value: Yup.number().nullable().required('O valor é obrigatório.'),
            description: Yup.string().nullable().required('A descrição é obrigatória.'),
            name: Yup.string().nullable().required('O nome é obrigatório.'),
        };

        const { success } = await onValidationSchema(shapeSchema, data);

        if (success) {
            let response: {
                success: boolean
                message: string
                data?: IAPlan
            } = {
                success: false,
                message: '',
            }

            if (!id) {
                response = await createPlanAsync(data);
            } else {
                response = await updatePlanAsync(data);
            }

            if (response?.success) {
                onSuccessMessage(
                    titleMessages,
                    id ? 'Sucesso ao atualizar o registro!' : 'Sucesso ao criar novo registro!'
                );

                queryClient.refetchQueries([QUERIES.PLAN.module]);
                onOpen(false);
            } else {
                onErrorMessage(titleMessages, 'Erro ao salvar o registro.');
            }
        }
    };


    useEffect(() => {
        if (id) {
            if (dataGet?.data) {
                setName(dataGet?.data?.name);
                setDescription(dataGet?.data?.description);
                setStatus(dataGet?.data?.status);
                setValue(dataGet?.data?.value);
                setBenefits(dataGet?.data?.benefits || []);
            }
        } else {
            setName('');
            setDescription('');
            setStatus('');
            setValue(0);
            setBenefits([]);
        }
    }, [dataGet, id])

    const isLoading = isLoadingGet || isLoadingCreate || isLoadingUpdate;

    return (
        <form onSubmit={onSubmit}>
            <Drawer
                isOpen={isOpen}
                onOpen={onHandleClose}
            >
                <Card
                    style={{ height: '100%' }}
                    styleContent={{ overflowY: 'auto', padding: 0 }}
                    headerTitle="Planos"
                    headerSubTitle={id ? 'Editar Registro' : 'Novo Registro'}
                    footer={
                        <ButtonsWrap>
                            <Button
                                type="button"
                                model="light"
                                onClick={onHandleClose}
                            >
                                Fechar
                            </Button>
                            <Button
                                type="submit"
                                isDisabled={isLoading}
                                isLoading={isLoading}
                            >
                                Salvar Plano
                            </Button>
                        </ButtonsWrap>
                    }
                >
                    <FormWrap>
                        <InputText
                            isRequired
                            value={name}
                            onChange={setName}
                            label="Nome"
                            name="name"
                            placeholder="Informe um nome"
                            isLoading={isLoading}
                        />

                        <InputText
                            isRequired
                            value={description}
                            onChange={setDescription}
                            label="Descrição"
                            name="description"
                            placeholder="Informe uma descrição"
                            isLoading={isLoading}

                        />

                        <InputNumber
                            isRequired
                            value={value}
                            onChange={setValue}
                            label="Valor"
                            name="value"
                            placeholder="Informe um valor"
                            isLoading={isLoading}
                            prefix="R$ "
                        />

                        <Select
                            isRequired
                            label="Status"
                            name="status"
                            placeholder="Selecione o status"
                            value={status}
                            onChange={setStatus}
                            isLoading={isLoading}
                            options={[
                                { value: 'ACTIVE', label: 'Ativo' },
                                { value: 'INACTIVE', label: 'Inativo' },
                            ]}
                        />

                        <PlanFormBenefitField 
                            isOpen={isOpenDrawerBenefit}
                            onOpen={setIsOpenDrawerBenefit}
                            items={benefits}
                            isLoading={isLoading}
                            onSave={setBenefits}
                        />


                    </FormWrap>
                </Card>
            </Drawer>
        </form>
    );
};