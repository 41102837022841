import { useCallback } from 'react';

import { Badge, Table } from 'components';
import { convertDateENToBR, convertFloatToBR } from 'utils';

import { useGetGatewayPaymentPaymentList } from 'services';

import { TableWrap, ColumnAmounts } from './styles'

export const GatewayPaymentPaymentTable = () => {

    const { data, isLoading } = useGetGatewayPaymentPaymentList()

    const columnStatus = useCallback(
        (value: string) => <Badge>{value}</Badge>,
        []
    );

    const columnAmount = useCallback(
        (netValue) => <ColumnAmounts>
            <strong>{convertFloatToBR(netValue || 0, 2, "R$ ")}</strong>
        </ColumnAmounts>,
        []
    );

    return (
        <TableWrap>
            <Table
                isLoading={isLoading}
                columns={[
                    {
                        dataField: 'description',
                        text: 'Descrição',
                    },
                    {
                        dataField: 'customer',
                        text: 'Usuário',
                    },
                    {
                        dataField: 'status',
                        text: 'Status',
                        formatter: (value) => columnStatus(value),
                    },
                    // {
                    //     dataField: 'billingType',
                    //     text: 'Tipo de Pagamento',
                    // },
                    {
                        dataField: 'value',
                        text: 'Total',
                        style: { minWidth: '9rem' },
                        formatter: (value) => convertFloatToBR(value || 0, 2, "R$ "),
                    },
                    {
                        dataField: 'netValue',
                        text: 'Líquido',
                        style: { minWidth: '9rem' },
                        formatter: (value) => columnAmount(value),
                    },
                    {
                        dataField: 'dateCreated',
                        text: 'Criado em',
                        style: { minWidth: '10rem' },
                        formatter: (value) => convertDateENToBR(value),
                        sort: true,
                    },
                    {
                        dataField: 'confirmedDate',
                        text: 'Confirmado em',
                        style: { minWidth: '10rem' },
                        formatter: (value) => convertDateENToBR(value),
                        sort: true,
                    },
                ]}
                data={data?.data || []}
            />
        </TableWrap>
    );
};
