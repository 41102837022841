import api from './api';

import type { IATracking } from '@types';

export const TRACKING_URL = 'trackings';

export const getTrackingList = async (): Promise<IATracking[]> => {
    const { data } = await api.get(TRACKING_URL);

    return data;
};

export const getTrackingDetail = async (id: string): Promise<IATracking> => {
    const { data } = await api.get(`${TRACKING_URL}/${id}`);

    return data;
};

export const removeTracking = async (
    id: number,
) => {
    const { data } = await api.delete(
        `${TRACKING_URL}/${id}`
    );

    return data;
};
