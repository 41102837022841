import promptImageUrl from 'assets/icons/operation.png';

import { Skeleton } from 'components';
import theme from 'styles/theme';

import {
    PromptSummaryWrap,
    PromptSummaryImage,
    PromptSummaryContent,
    PromptSummaryTitle,
} from './PromptSummary.style';

import type { PromptSummaryProps } from './PromptSummary.type';

const PromptSummaryLoading = () => (
        <PromptSummaryWrap>
            <Skeleton height={theme.spacing[14]} width={theme.spacing[14]} />

            <PromptSummaryContent>
                <PromptSummaryTitle>
                    <Skeleton height={theme.fontSizes[7]} width="12rem" />
                </PromptSummaryTitle>

            </PromptSummaryContent>
        </PromptSummaryWrap>
    );

const PromptSummaryDefault = ({ item, onEdit }: PromptSummaryProps) => (
        <PromptSummaryWrap onClick={onEdit}>
            <PromptSummaryImage
                alt="Prompt Image"
                src={promptImageUrl}
            />

            <PromptSummaryContent>
                <PromptSummaryTitle>{item?.content}</PromptSummaryTitle>

            </PromptSummaryContent>

        </PromptSummaryWrap>
    );

export const PromptSummary = ({
    item,
    isLoading,
    onEdit,
}: PromptSummaryProps) => {
    if (isLoading) {
        return <PromptSummaryLoading />;
    }

    return (
        <PromptSummaryDefault
            item={item}
            onEdit={onEdit}
        />
    );
};
