import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from 'styled-components';

import { AuthProvider } from 'contexts/AuthContext';
import { LayoutProvider } from 'contexts/LayoutContext';

import CustomRouter from 'routes/custom';
import customHistory from 'routes/history';

import theme from './styles/theme';
import GlobalStyle from './styles/global';
import Routes from './routes';

const App = () => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnMount: false,
                refetchOnWindowFocus: false,
            },
        },
    });

    return (
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <CustomRouter history={customHistory}>
                <AuthProvider>
                <LayoutProvider>
                    <ThemeProvider theme={theme}>
                        <GlobalStyle />
                        <Routes />
                    </ThemeProvider>
                </LayoutProvider>
                </AuthProvider>
            </CustomRouter>
        </QueryClientProvider>
    );
};

export default App;
