import styled from 'styled-components';

export const ResponsiveMenuWrap = styled.div`
    height: 100%;
    flex: 1;
    padding: ${(props) => props.theme.spacing[8]};

    display: flex;
    flex-direction: column;

    align-items: center;
    position: fixed;

    right: -60%;

    &.is-open-responsive-menu {
        transform: translateX(-100%);
        transition: transform 0.3s ease;

        background-color: ${(props) => props.theme.colors.base.white};
        width: 60%;

        z-index: 1000;
    }

    a {
        font-size: ${(props) => props.theme.fontSizes[6]};
        margin-right: ${(props) => props.theme.spacing[2]};

        &:hover,
        &.active {
            color: ${(props) => props.theme.colors.base.primary};
        }
    }
`;
