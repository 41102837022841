import styled from 'styled-components'

export const TableWrap = styled.div`
    width: 100%; 
    height: 100%; 
    overflow-y: auto;
`

export const UserInfoWrap = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    .name {
        font-size: ${(props) => props.theme.fontSizes[6]};
        color: ${(props) => props.theme.colors.text.dark};
        font-weight: 700;
    }

    .email {
        font-size: ${(props) => props.theme.fontSizes[7]};
        color: ${(props) => props.theme.colors.text.muted};
        font-weight: 400;
        word-wrap: break-word;
        overflow-wrap: break-word;
    }
    
`;
