import { BadgeWrap } from './styles';

import type { BadgeInterface } from './interface';

export const Badge = ({
    children,
    className,
    isCircle = false,
    isSquare = false,
    id,
    model = 'base',
    size = 'normal',
    style,
    variation = 'primary',
}: BadgeInterface) => (
    <BadgeWrap
        isCircle={isCircle}
        model={model}
        variation={variation}
        style={style}
        isSquare={isSquare}
        size={size}
        className={className}
        id={id}
    >
        {children}
    </BadgeWrap>
);

