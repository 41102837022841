/* eslint-disable arrow-body-style */
import axios from 'axios';

import history from 'routes/history';
import { ROUTES_AUTH } from '@constants';

import { enviroments, sweetAlert } from 'utils';

const api = axios.create({
    baseURL: `${enviroments.BASE_URL}/admin`,
    timeout: 5 * 60 * 1000,
});

api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const { status } = error.response;

        if (status === 403) {
            sweetAlert.fire({
                title: 'Servidor!',
                text:
                    error?.response?.data?.message ||
                    'Falha ao realizar requisição!',
                icon: 'error',
            });

            history.replace(ROUTES_AUTH.SIGN_IN.fullPath);
        }

        if (status === 401) {
            sweetAlert.fire({
                title: 'Servidor!',
                text:
                    error?.response?.data?.message ||
                    'Falha ao realizar requisição!',
                icon: 'error',
            });

            history.replace(ROUTES_AUTH.SIGN_IN.fullPath);
        }

        return Promise.reject(error);
    }
);

export default api;
