import styled from 'styled-components';

import { responsivator } from 'helpers';

export const HeaderWrap = styled.header`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    position: fixed;
    z-index: 200;
    /* justify-content: space-between; */

    .bars-responsive-sidebar {
        ${responsivator([
            `display: none;`,
            `display: none;`,
            `display: none;`,
            `display: flex;`,
        ])}

        align-items: center;
        height: 100%;

        margin-right: ${(props) => props.theme.spacing[4]};
        svg g [fill] {
            fill: ${(props) => props.theme.colors.base.primary};
        }
    }

    .logo {
        ${responsivator([
            `display: none;`,
            `display: none;`,
            `display: none;`,
            `display: flex;`,
        ])}

        align-items: center;
        height: 100%;
        margin-right: ${(props) => props.theme.spacing[4]};

        img {
            height: ${(props) => props.theme.spacing[14]};
        }
    }

    ${(props) =>
        responsivator([
            `width: calc(100vw - ${props.theme.spacing[28]});`,
            `width: calc(100vw - ${props.theme.spacing[28]});`,
            `width: calc(100vw - ${props.theme.spacing[28]});`,
            `width: 100vw;`,
        ])}

    height: ${(props) => props.theme.spacing[24]};

    ${(props) =>
        responsivator([
            `padding: 0 ${props.theme.spacing[8]}`,
            `padding: 0 ${props.theme.spacing[8]}`,
            `padding: 0 ${props.theme.spacing[8]}`,
            `padding: 0 ${props.theme.spacing[4]}`,
        ])}

    background-color: ${(props) => props.theme.colors.base.white};
    border-bottom: ${(props) => props.theme.borderSize} solid
        ${(props) => props.theme.colors.base.gray400};

    .header-title {
        ${(props) =>
            responsivator([
                `margin-right: ${props.theme.spacing[20]}`,
                `margin-right: ${props.theme.spacing[20]}`,
                `margin-right: ${props.theme.spacing[20]}`,
                `margin-right: auto`,
            ])}
    }

    .bars-responsive-menu {
        ${responsivator([
            `display: none;`,
            `display: none;`,
            `display: none;`,
            `display: flex;`,
        ])}

        align-items: center;
        height: 100%;

        margin-left: ${(props) => props.theme.spacing[4]};
        svg g [fill] {
            fill: ${(props) => props.theme.colors.base.primary};
        }
    }
`;
