import { AiOutlineUser } from 'react-icons/ai';
import { useTheme } from 'styled-components';

import { ReactComponent as ActiveCallIcon } from 'assets/svgs/icons/communication/ActiveCall.svg';
import { ReactComponent as MailIcon } from 'assets/svgs/icons/communication/Mail.svg';
import { ReactComponent as UserIcon } from 'assets/svgs/icons/generals/User.svg';

import { Badge, Card, Tabs } from 'components';

import { maskCellphone, convertDateENToBR } from 'utils';

import { BadgesWrap, BoxDates, HeaderWrap } from './styles';

import type { HeaderInterface } from './interface';

const Header = ({
    hasIntegration,
    createdAt,
    updatedAt,
    counts,
    email,
    login,
    name,
    phone,
    setTabValue,
    tabValue,
    subscriptionPlan
}: HeaderInterface) => {
    const theme = useTheme();

    const getPlan = () => {
        if (!subscriptionPlan || subscriptionPlan?.status === 'INACTIVE') {
            return (
                <Badge
                    model="light"
                    variation="warning"
                    size="normal"
                >
                    Sem Plano Contrato
                </Badge>
            )
        }

        return (
            <Badge
                model="base"
                variation="primary"
                size="normal"
            >
                {subscriptionPlan?.plan?.name}
            </Badge>
        );
    }

    return (
        <Card
            style={{
                marginBottom: theme.spacing[10],
            }}
            styleContent={{
                paddingBottom: '0',
            }}
        >
            <HeaderWrap>
                <div className="photo">
                    <AiOutlineUser />
                </div>

                <div className="box-right">
                    <div className="first-line">
                        <div className="box-name">
                            <h2>{name}</h2>

                            <BadgesWrap>

                                {hasIntegration ? (<Badge
                                    model="light"
                                    variation="primary"
                                    size="normal"
                                >
                                    Integração Ativa
                                </Badge>) : (<Badge
                                    model="light"
                                    variation="danger"
                                    size="normal"
                                >
                                    Sem Integração
                                </Badge>)}

                                {getPlan()}

                            </BadgesWrap>
                        </div>
                    </div>

                    <div className="box-info">
                        <div className="item">
                            <UserIcon />
                            <span>{login}</span>
                        </div>

                        <div
                            className="item"
                        >
                            <ActiveCallIcon />
                            <span>{phone ? phone?.split(",")?.map(item => maskCellphone(item)) : 'não informado'}</span>
                        </div>

                        <div className="item">
                            <MailIcon />
                            <span>{email || 'não informado'}</span>
                        </div>
                    </div>

                    <div className="box-registers">
                        <ol>
                            <li>
                                <span className="number">
                                    {counts?.fields || 0}
                                </span>
                                <span className="description">
                                    áreas de terra
                                </span>
                            </li>

                            <li>
                                <span className="number">
                                    {counts?.activities || 0}
                                </span>
                                <span className="description">atividades</span>
                            </li>

                            <li>
                                <span className="number">
                                    {counts?.patrimonies || 0}
                                </span>
                                <span className="description">patrimônios</span>
                            </li>

                            <li>
                                <span className="number">
                                    {counts?.finances || 0}
                                </span>
                                <span className="description">finanças</span>
                            </li>

                            <li>
                                <span className="number">
                                    {counts?.weathers || 0}
                                </span>
                                <span className="description">
                                    meteorologias
                                </span>
                            </li>
                        </ol>
                    </div>
                </div>
            </HeaderWrap>

            <BoxDates>
                <span>Criado em: {convertDateENToBR(createdAt)}</span>
                <span>Atualizado em: {convertDateENToBR(updatedAt)}</span>
            </BoxDates>

            <Tabs
                onChange={setTabValue}
                value={tabValue}
                items={[
                    { label: 'Análise', value: 'account-analytics' },
                    { label: 'Tags', value: 'account-tags' },
                    { label: 'Acesso', value: 'account-access' },
                    { label: 'Pagamentos', value: 'account-payments' },
                ]}
            />
        </Card>
    );
};

export default Header;
