import { useEffect } from 'react';
import { useTheme } from 'styled-components';
import { useQuery } from 'react-query';

import { Card, Grid } from 'components';
import { useLayout } from 'contexts/LayoutContext';
import { QUERIES, ROUTES_PANEL } from '@constants';
import { getTrackingList } from 'services';

import TrackingTable from '../components/Table';

const TrackingList = () => {
    const theme = useTheme();

    const { initLayout } = useLayout();

    useEffect(() => {
        initLayout('Rastreamento', [
            {
                label: 'Agtor Admin',
                link: ROUTES_PANEL.fullPath,
            },
            {
                label: 'Rastreamento',
                link: ROUTES_PANEL.TRACKINGS.fullPath,
            },
        ]);
    }, []);

    const { data, isLoading } = useQuery(QUERIES.TRACKING.base, () =>
        getTrackingList()
    );

    return (
        <Grid style={{ marginTop: theme.spacing[6] }}>
            <Card
                headerTitle="Lista de Rastreamento"
                headerSubTitle="Registros dos últimos 30 dias"
            >
                <TrackingTable
                    data={data}
                    isLoading={isLoading}
                />
            </Card>
        </Grid>
    );
};

export default TrackingList;
