import axios from 'axios';

import { enviroments } from 'utils';

const api = axios.create({
    baseURL: enviroments.BASE_URL,
    timeout: 5 * 60 * 1000,
});

export default api;
