import { Link } from 'react-router-dom';
import { BiBarChartAlt2 } from 'react-icons/bi';

import { Tooltip } from 'components';
import { ROUTES_PANEL } from '@constants/routes';

import Logo from 'assets/images/logo_icon_two.png';

import { SidebarWrap } from './styles';

import type { SidebarInterface } from './interface';

const Sidebar = ({ isOpenResponsiveSidebar }: SidebarInterface) => (
    <SidebarWrap
        className={isOpenResponsiveSidebar ? 'is-open-responsive-sidebar' : ''}
    >
        <Link className="logo" to="/">
            <img
                alt="logo do agtor, é um círculo de cor amaralo com quatro sementes dentro na cor branca"
                src={Logo}
            />
        </Link>

        <ul>
            <li>
                <Tooltip label="Dashboard" position="right" startContent={1.75}>
                    <Link
                        to={ROUTES_PANEL.DASHBOARD.fullPath}
                        className="sidebar-link"
                    >
                        <BiBarChartAlt2 />
                    </Link>
                </Tooltip>
            </li>
        </ul>
    </SidebarWrap>
);

export default Sidebar;
