import { useCallback } from 'react';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import { useMutation, useQueryClient } from 'react-query';
import { useTheme } from 'styled-components';

import { QUERIES } from '@constants';
import { Button, Table } from 'components';
import { onErrorMessage, onSuccessMessage } from 'helpers';
import { removeAccountsTags } from 'services';
import { distanceToNow, sweetAlert } from 'utils';

import type {
    AccountsTagsTableInterface,
    AccountTagRemoveMutationInterface,
} from './interface';

const AccountTagTable = ({
    data,
    isLoading,
    onSelectRegister,
}: AccountsTagsTableInterface) => {
    const theme = useTheme();

    const queryClient = useQueryClient();

    const titleMessages = 'Tag do Usuário';

    const { mutate: removeRegister }: AccountTagRemoveMutationInterface =
        useMutation((id) => removeAccountsTags(String(id)), {
            mutationKey: QUERIES.ACCOUNT_TAG.remove,
            onSuccess: () => {
                onSuccessMessage(
                    titleMessages,
                    'Registro removido com sucesso!'
                );

                queryClient.invalidateQueries(QUERIES.ACCOUNT_TAG.list);
            },
            onError: (err) => {
                onErrorMessage(titleMessages, err);
            },
        });

    function onRemoveRegisterRequest(id: number) {
        sweetAlert
            .fire({
                title: 'Você tem certeza?',
                text: 'Se você confirmar este registro será removido e está ação não poderá ser revertida!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: theme.colors.base.primary,
                cancelButtonColor: theme.colors.base.danger,
                confirmButtonText: 'Sim, remover registro!',
                cancelButtonText: 'Não, cliquei errado!',
            })
            .then((result) => {
                if (result.isConfirmed) {
                    removeRegister(id);
                }
            });
    }

    const accountTagOption = useCallback(
        ({ id }) => (
            <div>
                <Button
                    model="base"
                    variation="primary"
                    onClick={() => onSelectRegister(id)}
                    isSquare
                    style={{ marginLeft: theme.spacing[2] }}
                >
                    <AiOutlineEdit size={theme.fontSizes[5]} />
                </Button>

                <Button
                    model="base"
                    variation="danger"
                    onClick={() => onRemoveRegisterRequest(id)}
                    isSquare
                    style={{ marginLeft: theme.spacing[2] }}
                >
                    <AiOutlineDelete size={theme.fontSizes[5]} />
                </Button>
            </div>
        ),
        []
    );

    return (
        <div style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
            <Table
                isLoading={isLoading}
                columns={[
                    {
                        dataField: 'name',
                        text: 'Nome',
                        sort: true,
                        style: { width: '50%' },
                    },
                    {
                        dataField: 'slug',
                        text: 'Slug',
                        sort: true,
                        style: { width: '50%' },
                    },
                    {
                        dataField: 'createdAt',
                        text: 'Criado em',
                        style: { minWidth: '14rem' },
                        formatter: (value) => distanceToNow(value),
                        sort: true,
                    },
                    {
                        dataField: 'updatedAt',
                        text: 'Atualiado em',
                        style: { minWidth: '14rem' },
                        formatter: (value) => distanceToNow(value),
                        sort: true,
                    },
                    {
                        dataField: 'id',
                        text: 'Opções',
                        style: { minWidth: '10rem' },

                        formatter: (_value, _index, row) =>
                            accountTagOption(row),
                    },
                ]}
                data={data || []}
            />
        </div>
    );
};

export default AccountTagTable;
