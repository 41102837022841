import { useQuery, useMutation } from 'react-query';

import { QUERIES } from '@constants';
import { IAPlan } from '@types';

import api from './api';

export const PLAN_URL = 'plans';

export const useGetPlanList = () => {
    const getPlanList = async (): Promise<{
        success: boolean
        message: string
        data?: IAPlan[]
    }> => {

        try {
            const { data } = await api.get(PLAN_URL);

            return {
                success: true,
                message: 'Registros encontrados com sucesso!',
                data,
            };
        } catch (error) {
            return {
                success: false,
                message: 'Erro ao buscar registros!',
            };
        }
    };

    return useQuery({
        queryKey: [
            QUERIES.PLAN.module,
        ],
        queryFn: () => getPlanList(),
    });
};

export const useGetPlanDetail = (id?: string) => {
    const getPlanDetail = async (): Promise<{
        success: boolean
        message: string
        data?: IAPlan
    }> => {

        try {
            const { data } = await api.get(`${PLAN_URL}/${id}`);

            return {
                success: true,
                message: 'Registro encontrado com sucesso!',
                data,
            };
        } catch (error) {
            return {
                success: false,
                message: 'Erro ao buscar registro!',
            };
        }
    };

    return useQuery({
        queryKey: [QUERIES.PLAN.module, id],
        queryFn: () => getPlanDetail(),
        enabled: Boolean(id),
    });
};

interface PlanOptionsProps {
    onError?: (error: any, variables: any, context: any) => void;
    onSuccess?: (data: any, variables: any, context: any) => void;
}

export const useUpdatePlan = (id: string, options?: PlanOptionsProps) => {
    const updateFinance = async (payload: Partial<IAPlan>): Promise<{
        success: boolean
        message: string
        data?: IAPlan
    }> => {
        try {
            const url = `${PLAN_URL}/${id}`;

            const { data } = await api.put(url, payload);


            return {
                success: true,
                message: 'Registro atualizado com sucesso!',
                data,
            };
        } catch (error) {
            return {
                success: false,
                message: 'Erro ao atualizar registro!',
            };
        }
    };

    return useMutation({
        mutationKey: [QUERIES.PLAN.module, id],
        mutationFn: (payload: Partial<IAPlan>) =>
            updateFinance(payload),
        ...options

    });
};

export const useCreatePlan = (options?: PlanOptionsProps) => {

    const createFinance = async (payload: Partial<IAPlan>): Promise<{
        success: boolean
        message: string
        data?: IAPlan
    }> => {
        try {
            const url = `${PLAN_URL}`;

            const { data } = await api.post(url, payload);

            return {
                success: true,
                message: 'Registro criado com sucesso!',
                data,
            }

        } catch (error) {
            return {
                success: false,
                message: 'Erro ao criar registro!',
            };
        }

    };

    return useMutation({
        mutationKey: [QUERIES.PLAN.module],
        mutationFn: (payload: Partial<IAPlan>) =>
            createFinance(payload),
        ...options

    });
};

export const useRemovePlan = () => {

    const removeFinance = async (id: string): Promise<{
        success: boolean
        message: string
        data?: undefined
    }> => {
        try {
            const url = `${PLAN_URL}/${id}`;

            await api.delete(url);

            return {
                success: true,
                message: 'Registro removido com sucesso!',
            };

        } catch (error) {
            return {
                success: false,
                message: 'Erro ao remover registro!',
            };
        }
    };

    return useMutation({
        mutationKey: [QUERIES.PLAN.module],
        mutationFn: (id: string) =>
            removeFinance(id),

    });
};



