import benefitImageUrl from 'assets/icons/operation.png';

import { Skeleton } from 'components';
import theme from 'styles/theme';

import {
    BenefitSummaryWrap,
    BenefitSummaryImage,
    BenefitSummaryContent,
    BenefitSummaryTitle,
} from './BenefitSummary.style';

import type { BenefitSummaryProps } from './BenefitSummary.type';

const BenefitSummaryLoading = () => (
        <BenefitSummaryWrap>
            <Skeleton height={theme.spacing[14]} width={theme.spacing[14]} />

            <BenefitSummaryContent>
                <BenefitSummaryTitle>
                    <Skeleton height={theme.fontSizes[7]} width="12rem" />
                </BenefitSummaryTitle>

            </BenefitSummaryContent>
        </BenefitSummaryWrap>
    );

const BenefitSummaryDefault = ({ item, onEdit }: BenefitSummaryProps) => (
        <BenefitSummaryWrap onClick={onEdit}>
            <BenefitSummaryImage
                alt="Benefit Image"
                src={benefitImageUrl}
            />

            <BenefitSummaryContent>
                <BenefitSummaryTitle>{item?.name}</BenefitSummaryTitle>

            </BenefitSummaryContent>

        </BenefitSummaryWrap>
    );

export const BenefitSummary = ({
    item,
    isLoading,
    onEdit,
}: BenefitSummaryProps) => {
    if (isLoading) {
        return <BenefitSummaryLoading />;
    }

    return (
        <BenefitSummaryDefault
            item={item}
            onEdit={onEdit}
        />
    );
};
