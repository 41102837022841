import { useQuery, useMutation } from 'react-query';

import { QUERIES } from '@constants';
import { IAAssistantAgent } from '@types';

import api from './api';

export const ASSISTANT_AGENT_URL = 'assistant_agents';

export const useGetAssistantAgentList = () => {
    const getAssistantAgentList = async (): Promise<{
        success: boolean
        message: string
        data?: IAAssistantAgent[]
    }> => {

        try {
            const { data } = await api.get(ASSISTANT_AGENT_URL);

            return {
                success: true,
                message: 'Registros encontrados com sucesso!',
                data,
            };
        } catch (error) {
            return {
                success: false,
                message: 'Erro ao buscar registros!',
            };
        }
    };

    return useQuery({
        queryKey: [
            QUERIES.ASSISTANT_AGENT.module,
        ],
        queryFn: () => getAssistantAgentList(),
    });
};

export const useGetAssistantAgentDetail = (id?: string) => {
    const getAssistantAgentDetail = async (): Promise<{
        success: boolean
        message: string
        data?: IAAssistantAgent
    }> => {

        try {
            const { data } = await api.get(`${ASSISTANT_AGENT_URL}/${id}`);

            return {
                success: true,
                message: 'Registro encontrado com sucesso!',
                data,
            };
        } catch (error) {
            return {
                success: false,
                message: 'Erro ao buscar registro!',
            };
        }
    };

    return useQuery({
        queryKey: [QUERIES.PLAN.module, id],
        queryFn: () => getAssistantAgentDetail(),
        enabled: Boolean(id),
    });
};

interface AssistantAgentOptionsProps {
    onError?: (error: any, variables: any, context: any) => void;
    onSuccess?: (data: any, variables: any, context: any) => void;
}

export const useUpdateAssistantAgent = (id: string, options?: AssistantAgentOptionsProps) => {
    const updateFinance = async (payload: Partial<IAAssistantAgent>): Promise<{
        success: boolean
        message: string
        data?: IAAssistantAgent
    }> => {
        try {
            const url = `${ASSISTANT_AGENT_URL}/${id}`;

            const { data } = await api.put(url, payload);


            return {
                success: true,
                message: 'Registro atualizado com sucesso!',
                data,
            };
        } catch (error) {
            return {
                success: false,
                message: 'Erro ao atualizar registro!',
            };
        }
    };

    return useMutation({
        mutationKey: [QUERIES.PLAN.module, id],
        mutationFn: (payload: Partial<IAAssistantAgent>) =>
            updateFinance(payload),
        ...options

    });
};

export const useCreateAssistantAgent = (options?: AssistantAgentOptionsProps) => {

    const createFinance = async (payload: Partial<IAAssistantAgent>): Promise<{
        success: boolean
        message: string
        data?: IAAssistantAgent
    }> => {
        try {
            const url = `${ASSISTANT_AGENT_URL}`;

            const { data } = await api.post(url, payload);

            return {
                success: true,
                message: 'Registro criado com sucesso!',
                data,
            }

        } catch (error) {
            return {
                success: false,
                message: 'Erro ao criar registro!',
            };
        }

    };

    return useMutation({
        mutationKey: [QUERIES.PLAN.module],
        mutationFn: (payload: Partial<IAAssistantAgent>) =>
            createFinance(payload),
        ...options

    });
};

export const useRemoveAssistantAgent = () => {

    const removeFinance = async (id: string): Promise<{
        success: boolean
        message: string
        data?: undefined
    }> => {
        try {
            const url = `${ASSISTANT_AGENT_URL}/${id}`;

            await api.delete(url);

            return {
                success: true,
                message: 'Registro removido com sucesso!',
            };

        } catch (error) {
            return {
                success: false,
                message: 'Erro ao remover registro!',
            };
        }
    };

    return useMutation({
        mutationKey: [QUERIES.PLAN.module],
        mutationFn: (id: string) =>
            removeFinance(id),

    });
};



