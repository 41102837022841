import { useCallback } from 'react';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import { useQueryClient } from 'react-query';
import { useTheme } from 'styled-components';

import { Button, Table, Badge } from 'components';
import { QUERIES } from '@constants';
import { onErrorMessage, onSuccessMessage } from 'helpers';
import { distanceToNow, sweetAlert, convertFloatToBR } from 'utils';

import { useRemovePlan } from 'services'

import type {
    PlanTableInterface,
} from './interface';

export const PlanTable = ({
    data,
    isLoading,
    onSelectRegister,
}: PlanTableInterface) => {
    const theme = useTheme();

    const { mutateAsync: removePlanAsync } = useRemovePlan();

    const queryClient = useQueryClient();

    const titleMessages = 'Planos';


    function onRemoveRegisterRequest(id: string) {
        sweetAlert
            .fire({
                title: 'Você tem certeza?',
                text: 'Se você confirmar este registro será removido e está ação não poderá ser revertida!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: theme.colors.base.primary,
                cancelButtonColor: theme.colors.base.danger,
                confirmButtonText: 'Sim, remover registro!',
                cancelButtonText: 'Não, cliquei errado!',
            })
            .then(async (result) => {
                if (result.isConfirmed) {

                    const response = await removePlanAsync(id);

                    if (response.success) {
                        onSuccessMessage(
                            titleMessages,
                            'Registro removido com sucesso!'
                        );

                        queryClient.invalidateQueries(QUERIES.PLAN.module);

                    } else {
                        onErrorMessage(titleMessages, 'Erro ao remover registro!');
                    }
                }
            });
    }

    const planStatus = useCallback(
        (props: { status: string }) => {
            if (props.status === 'ACTIVE') {
                return <Badge variation="success" model='light'>Ativo</Badge>;
            }

            return <Badge variation="danger" model='light'>Inativo</Badge>;

        }, []);

    const planOption = useCallback(
        ({ id }) => (
            <div>
                <Button
                    model="light"
                    variation="primary"
                    onClick={() => onSelectRegister(id)}
                    isSquare
                    style={{ marginLeft: theme.spacing[2] }}
                >
                    <AiOutlineEdit size={theme.fontSizes[5]} />
                </Button>

                <Button
                    model="base"
                    variation="danger"
                    onClick={() => onRemoveRegisterRequest(id)}
                    isSquare
                    style={{ marginLeft: theme.spacing[2] }}
                >
                    <AiOutlineDelete size={theme.fontSizes[5]} />
                </Button>
            </div>
        ),
        []
    );

    return (
        <div style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
            <Table
                isLoading={isLoading}
                columns={[
                    {
                        dataField: 'name',
                        text: 'Nome',
                        sort: true,
                        style: { width: '20%' },
                    },
                    {
                        dataField: 'value',
                        text: 'Valor',
                        style: { width: '20%' },
                        formatter: (value) => `R$ ${convertFloatToBR(value)}`,
                    },
                    {
                        dataField: 'status',
                        text: 'Status',
                        style: { width: '10%' },
                        formatter: (value) => planStatus({ status: value }),
                    },
                    {
                        dataField: 'isRecommended',
                        text: 'Recomendado',
                        style: { width: '10%' },
                        formatter: (value) => (value ? 'Sim' : 'Não'),
                    },
                    {
                        dataField: 'createdAt',
                        text: 'Criado em',
                        style: { minWidth: '14rem' },
                        formatter: (value) => distanceToNow(value),
                        sort: true,
                    },
                    {
                        dataField: 'updatedAt',
                        text: 'Atualiado em',
                        style: { minWidth: '14rem' },
                        formatter: (value) => distanceToNow(value),
                        sort: true,
                    },
                    {
                        dataField: 'id',
                        text: 'Opções',
                        style: { minWidth: '10rem' },

                        formatter: (_value, _index, row) =>
                            planOption(row),
                    },
                ]}
                data={data || []}
            />
        </div>
    );
};

