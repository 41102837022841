import styled from 'styled-components';

import { responsivator } from 'helpers';

export const HeaderWrap = styled.div`
    display: flex;
    width: 100%;

    ${responsivator([
        'flex-direction: row;',
        'flex-direction: row;',
        'flex-direction: row;',
        'flex-direction: row;',
        'flex-direction: row;',
        'flex-direction: column;',
    ])}

    .photo {
        display: flex;
        align-items: center;
        justify-content: center;

        ${(props) =>
            responsivator([
                `margin: 0 ${props.theme.spacing[7]} 0 0;`,
                `margin: 0 ${props.theme.spacing[7]} 0 0;`,
                `margin: 0 ${props.theme.spacing[7]} 0 0;`,
                `margin: 0 ${props.theme.spacing[7]} 0 0;`,
                `margin: 0 ${props.theme.spacing[7]} 0 0;`,
                `margin: 0 auto ${props.theme.spacing[6]} auto;`,
            ])};

        height: ${(props) => props.theme.spacing[38]};
        width: ${(props) => props.theme.spacing[38]};
        border-radius: ${(props) => props.theme.borderRadius.default};

        background-color: ${(props) => props.theme.colors.base.gray200};
        background-size: cover;
        background-position: center;

        svg {
            font-size: ${(props) => props.theme.fontSizes['4qx']};
        }
    }

    .box-name {
        display: flex;

        ${responsivator([
            'flex-direction: row;',
            'flex-direction: row;',
            'flex-direction: row;',
            'flex-direction: row;',
            'flex-direction: row;',
            'flex-direction: column;',
        ])}

        align-items: center;
        margin-top: ${(props) => props.theme.spacing[1]};

        h2 {
            font-size: ${(props) => props.theme.fontSizes[2]};
            font-weight: 700;
            color: ${(props) => props.theme.colors.text.gray900};

            margin-right: ${(props) => props.theme.spacing[4]};

            ${(props) =>
                responsivator([
                    'margin-bottom: 0;',
                    'margin-bottom: 0;',
                    'margin-bottom: 0;',
                    'margin-bottom: 0;',
                    'margin-bottom: 0;',
                    `margin-bottom: ${props.theme.spacing[3]}`,
                ])}
        }
    }

    .box-info {
        display: flex;
        ${responsivator([
            'flex-direction: row;',
            'flex-direction: row;',
            'flex-direction: row;',
            'flex-direction: row;',
            'flex-direction: column;',
        ])}

        margin-top: ${(props) => props.theme.spacing[4]};
        margin-bottom: ${(props) => props.theme.spacing[7]};

        .item {
            display: flex;
            align-items: center;
            margin-right: ${(props) => props.theme.spacing[5]};

            span {
                font-size: ${(props) => props.theme.fontSizes[6]};
                color: ${(props) => props.theme.colors.text.gray400};
                font-weight: 700;
            }

            svg {
                height: ${(props) => props.theme.spacing[5]};
                width: ${(props) => props.theme.spacing[5]};
                margin-right: ${(props) => props.theme.spacing[2]};

                g [fill] {
                    fill: ${(props) => props.theme.colors.base.secondary};
                }
            }
        }
    }

    .box-registers {
        ol {
            ${responsivator([
                '',
                '',
                '',
                '',
                'display: block;',
                'display: grid;',
            ])}

            grid-template-columns: 1fr 1fr;
            grid-gap: ${(props) => props.theme.spacing[4]};

            li {
                display: inline-block;
                ${(props) =>
                    responsivator([
                        '',
                        '',
                        '',
                        '',
                        `width: ${props.theme.spacing[36]};`,
                        'width: 100%;',
                    ])}

                ${responsivator([
                    '',
                    '',
                    '',
                    '',
                    'text-align: initial;',
                    'text-align: center;',
                ])}

                ${(props) =>
                    responsivator([
                        '',
                        '',
                        '',
                        '',
                        `margin: 0 ${props.theme.spacing[4]} ${props.theme.spacing[4]} 0`,
                        'margin: 0;',
                    ])}

                padding-top: ${(props) => props.theme.spacing[3]};
                padding-bottom: ${(props) => props.theme.spacing[3]};
                padding-left: ${(props) => props.theme.spacing[4]};
                padding-right: ${(props) => props.theme.spacing[4]};
                border: ${(props) => props.theme.borderSize.default} dashed
                    ${(props) => props.theme.colors.base.gray400};
                border-radius: ${(props) => props.theme.borderRadius.default};

                .number {
                    display: block;
                    font-size: ${(props) => props.theme.fontSizes[2]};
                    color: ${(props) => props.theme.colors.base.primary};
                    font-weight: 700;
                    margin-bottom: ${(props) => props.theme.spacing[1]};
                }

                .description {
                    display: block;

                    color: ${(props) => props.theme.colors.text.gray400};
                    font-weight: 400;

                    ${(props) =>
                        responsivator([
                            `font-size: ${props.theme.fontSizes[6]};`,
                            `font-size: ${props.theme.fontSizes[6]};`,
                            `font-size: ${props.theme.fontSizes[6]};`,
                            `font-size: ${props.theme.fontSizes[6]};`,
                            `font-size: ${props.theme.fontSizes[6]};`,
                            `font-size: ${props.theme.fontSizes[8]};`,
                        ])}
                }
            }
        }
    }
`;

export const BadgesWrap = styled.div`
    display: flex;
    align-items: center;

    gap: ${(props) => props.theme.spacing[2]};
`

export const BoxDates = styled.div`
    width: 100%;
    display: flex;

    margin-top: ${(props) => props.theme.spacing[4]};

    ${responsivator([
        'flex-direction: row;',
        'flex-direction: row;',
        'flex-direction: row;',
        'flex-direction: row;',
        'flex-direction: row;',
        'flex-direction: column;',
    ])}

    span {
        display: block;
        margin-left: ${(props) => props.theme.spacing[2]};
        font-size: ${(props) => props.theme.fontSizes[8]};
        color: ${(props) => props.theme.colors.text.gray400};

        ${(props) =>
            responsivator([
                `margin-top: 0`,
                `margin-top: 0`,
                `margin-top: 0`,
                `margin-top: 0`,
                `margin-top: 0`,
                `margin-top: ${props.theme.spacing[1]}`,
            ])}
    }
`;
