
import { useMemo, useRef, useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js'
import 'chart.js/auto'

import ResizableBox  from '../RisizableBox'

import type { LineChartProps } from './interface'

ChartJS.register(LineElement, CategoryScale, LinearScale, Tooltip, Legend)

const LineChart = ({
  data,
  height,
  width,
  onLegendClick = () => null,
  onChartClick = () => null,
}: LineChartProps) => {
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  })

  const myRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setDimensions({
      width: myRef?.current?.clientWidth || 0,
      height: myRef?.current?.clientHeight || 0,
    })
  }, [myRef?.current?.clientHeight, myRef?.current?.clientWidth])

  const chartData = useMemo(
    () => ({
      labels: data?.[0]?.data.map((item) => item.label),
      datasets: data?.map((item) => ({
        label: item.label,
        data: item?.data?.map((dataItem) => dataItem.value),
        backgroundColor: item.backgroundColor,
        borderColor: item.borderColor,
      })),
    }),
    [data],
  )

  return (
    <div ref={myRef} style={{ width: width || '100%' }}>
      <ResizableBox width={width} height={height}>
        {dimensions.height !== 0 && dimensions.width !== 0 ? (
          <Line
            height={dimensions.height}
            width={dimensions.width}
            data={chartData}
            options={{
              onClick: onChartClick,
              plugins: {
                legend: {
                    onClick: function(e, legendItem, legend) { //eslint-disable-line
                    onLegendClick()
                    ChartJS.defaults.plugins.legend.onClick.call(
                      this,
                      e,
                      legendItem,
                      legend,
                    )
                  },
                  position: 'bottom',
                },
              },
              scales: {
                x: {
                  beginAtZero: true,
                },
                y: {
                  type: 'linear',
                },
              },
            }}
          />
        ) : (
          <div />
        )}
      </ResizableBox>
    </div>
  )
}

export default LineChart