import Cookies from 'js-cookie';
import {
    createContext,
    ReactNode,
    useContext,
    useLayoutEffect,
    useState,
    useMemo,
} from 'react';
import { useMutation, UseMutationOptions } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import { api, postSignIn } from 'services';
import { onErrorMessage, onSuccessMessage, onValidationSchema } from 'helpers';
import { ROUTES_AUTH, ROUTES_PANEL } from '@constants/routes';

import { QUERIES } from '@constants';

interface UserSignInProps {
    login: string;
    password: string;
}

export interface SignInMutationInterface extends UseMutationOptions {
    mutate: (payload: UserSignInProps) => void;
    isLoading: boolean;
}

interface AuthContextData {
    isSigned: boolean;
    user: any;
    token?: string;
    isLoading: boolean;
    signIn: (user: UserSignInProps) => void;
    signOut: () => void;
    onHandleNavigateWithNext: (link: string) => void;
}

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthContext = createContext({} as AuthContextData);

const AuthProvider = ({ children }: AuthProviderProps) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [initStarted, setInitStarted] = useState(false);
    const [token, setToken] = useState<string | undefined>(undefined);
    const [user, setUser] = useState(null);

    function onHandleNavigateWithNext(link: string) {
        const next = searchParams.get('next');

        if (next) {
            navigate(next);
        }

        navigate(link);
    }

    const { mutate: mutateLogin, isLoading }: SignInMutationInterface =
        useMutation((payload) => postSignIn(payload), {
            mutationKey: QUERIES.AUTH.signIn,
            onSuccess: (response) => {
                setUser(response.user);
                setToken(response.token);

                onSuccessMessage('Login', 'Sucesso ao efetuar login!');

                onHandleNavigateWithNext(ROUTES_PANEL.DASHBOARD.fullPath);
            },
            onError: (err) => onErrorMessage('Login', err),
        });

    async function signIn(data: UserSignInProps) {
        const schema = {
            password: Yup.string()
                .nullable()
                .required('A senha é obrigatória.'),
            login: Yup.string().nullable().required('O login é obrigatório.'),
        };

        const result = await onValidationSchema(schema, data, true);

        if (result.success) {
            mutateLogin(data);
        }
    }

    function signOut() {
        setUser(null);
        setToken(undefined);

        Cookies.remove('agtor@adminapp.token');

        api.defaults.headers.common.Authorization = '';

        onHandleNavigateWithNext(ROUTES_AUTH.SIGN_IN.fullPath);
    }

    useLayoutEffect(() => {
        if (initStarted && token !== undefined) {
            Cookies.set('agtor@adminapp.token', String(token));
        }

        api.defaults.headers.common.Authorization = `Bearer ${token}`;
    }, [token]);

    useLayoutEffect(() => {
        const initToken = Cookies.get('agtor@adminapp.token');

        setToken(initToken ? String(initToken) : undefined);
        
        api.defaults.headers.common.Authorization = `Bearer ${initToken}`;
        
        if(initToken) {
            navigate(ROUTES_PANEL.DASHBOARD.fullPath);
        }

        setInitStarted(true);
    }, []);

    const valueProvider = useMemo(
        () => ({
            isSigned: Boolean(token || !initStarted),
            user,
            token,
            isLoading,
            signIn,
            signOut,
            onHandleNavigateWithNext,
        }),
        [user, token, isLoading, initStarted]
    );

    return (
        <AuthContext.Provider value={valueProvider}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = (): AuthContextData => {
    const context = useContext(AuthContext);

    return context;
};

export { AuthProvider, useAuth };
