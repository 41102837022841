import styled from 'styled-components';

export const UserInfoWrap = styled.div`
    display: flex;
    align-items: center;

    .photo {
        display: flex;
        align-items: center;
        justify-content: center;

        height: ${(props) => props.theme.spacing[14]};
        width: ${(props) => props.theme.spacing[14]};
        border-radius: ${(props) => props.theme.borderRadius.default};

        background-color: ${(props) => props.theme.colors.base.gray200};
        background-size: cover;
        background-position: center;

        margin-right: ${(props) => props.theme.spacing[5]};

        svg {
            font-size: ${(props) => props.theme.fontSizes[1]};
        }
    }

    .infos {
        display: flex;
        flex-direction: column;
        flex: 1;

        .name {
            font-size: ${(props) => props.theme.fontSizes[6]};
            color: ${(props) => props.theme.colors.text.dark};
            font-weight: 700;
        }

        .login {
            font-size: ${(props) => props.theme.fontSizes[7]};
            color: ${(props) => props.theme.colors.text.muted};
            font-weight: 400;
        }
    }
`;
