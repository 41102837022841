import { useCallback, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useTheme } from 'styled-components';

import { AiOutlineLock, AiOutlineUser, AiOutlineWhatsApp } from 'react-icons/ai';
import { Badge, Button, Card, Grid, InputText, Modal, Table } from 'components';
import { onErrorMessage, onSuccessMessage } from 'helpers';
import { updateUsers } from 'services';

import { QUERIES } from '@constants';

import { distanceToNow, maskCellphone } from 'utils';
import { IAUser } from '@types';
import { UserInfoWrap } from './styles';
import type { CardAccountAccessInterface } from './interface';

const CardAccountAccess = ({ data }: CardAccountAccessInterface) => {
    const theme = useTheme();
    const queryClient = useQueryClient();

    const [isOpenModal, setIsOpenModal] = useState(false);

    const [userId, setUserId] = useState('');
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    const { mutate: onSubmitUser, isLoading: isLoadingUser } = useMutation(
        () => updateUsers(userId, { login, password }),
        {
            mutationKey: [QUERIES.ACCOUNT.module],
            onSuccess: () => {
                queryClient.refetchQueries(QUERIES.ACCOUNT.module);

                onSuccessMessage('Usuário', 'Sucesso ao atualizar usuário!');

                setIsOpenModal(false);
            },
            onError: (err) => onErrorMessage('Usuário', err),
        }
    );

    const userInfo = useCallback(
        (localUserInfo) => (
            <UserInfoWrap>
                <div className="photo">
                    <AiOutlineUser />
                </div>
                <div className="infos">
                    <span className="name">{localUserInfo?.name}</span>
                    <span className="login">{localUserInfo?.login}</span>
                </div>
            </UserInfoWrap>
        ),
        []
    );

    const userType = useCallback(
        (value) => (
            <Badge variation="secondary">{value}</Badge>
        ),
        []
    );

    const handleOpenWhatsApp = (number: string) => {
        window.open(`https://api.whatsapp.com/send?phone=55${number}`);
    };


    const onOpenModal = (user: IAUser) => {
        setUserId(user.id);
        setLogin(user.login);
        setPassword('');
        setIsOpenModal(true);
    }

    const userOption = useCallback(
        (value) => (
            <div>
                <Button
                    model="light"
                    variation="primary"
                    onClick={() => handleOpenWhatsApp(value.phone)}
                    style={{ marginLeft: theme.spacing[2] }}
                    isSquare
                >
                    <AiOutlineWhatsApp size={theme.fontSizes[5]} />
                </Button>

                <Button
                    model="base"
                    variation="primary"
                    onClick={() => onOpenModal(value)}
                    isSquare
                    style={{ marginLeft: theme.spacing[2] }}
                >
                    <AiOutlineLock size={theme.fontSizes[5]} />
                </Button>
            </div>
        ),
        []
    );

    return (
        <Card
            headerTitle="Acesso da Conta"
        >
        <div style={{ width: '100%', height: '100%', overflowY: 'auto' }}>

            <Table
                columns={[
                    {
                        dataField: 'name',
                        text: 'Nome',
                        sort: true,
                        style: { width: '100%' },
                        formatter: (_value, _index, row) => userInfo(row),
                    },
                    {
                        dataField: 'type',
                        text: 'Tipo',
                        sort: true,
                        formatter: (value) => userType(value),
                    },
                    {
                        dataField: 'phone',
                        text: 'Telefone',
                        style: { minWidth: '14rem' },
                        formatter: (value) => maskCellphone(value),
                    },
                    {
                        dataField: 'createdAt',
                        text: 'Criado em',
                        style: { minWidth: '14rem' },
                        formatter: (value) => distanceToNow(value),
                        sort: true,
                    },
                    {
                        dataField: 'updatedAt',
                        text: 'Atualiado em',
                        style: { minWidth: '14rem' },
                        formatter: (value) => distanceToNow(value),
                        sort: true,
                    },
                    {
                        dataField: 'id',
                        text: 'Opções',
                        style: { minWidth: '15rem' },

                        formatter: (_value, _index, row) => userOption(row),
                    },
                ]}
                data={data.map((userAccess) => ({
                    type: userAccess.type,
                    accountsIds: userAccess.accountsIds,
                    usersIds: userAccess.usersIds,
                    ...userAccess.users
                }))}
            />
            
            </div>

            <Modal
                title="Editar Acesso"
                subTitle="Para o Usuário"
                isOpen={isOpenModal}
                onCloseModal={() => setIsOpenModal(false)}
                footer={
                    <Button
                        isLoading={isLoadingUser}
                        onClick={() => onSubmitUser()}
                        variation="secondary"
                    >
                        Salvar
                    </Button>
                }
            >
                <Grid numberColumns={1} gridGap={theme.spacing[8]}>
                    <InputText
                        label="Digite o login do usuário"
                        name="login"
                        placeholder="Digite o novo login"
                        value={login}
                        onChange={setLogin}
                    />
                    <InputText
                        label="Digite a nova senha do usuário"
                        name="password"
                        placeholder="Digite a nova senha"
                        value={password}
                        onChange={setPassword}
                        isPassword
                    />
                </Grid>
            </Modal>
        </Card>
    );
};

export default CardAccountAccess;
