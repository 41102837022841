import { useState, FormEvent } from 'react';
import { useTheme } from 'styled-components';

import { Button, InputText } from 'components';
import { useAuth } from 'contexts/AuthContext';

import { SignInWrap } from './styles';

const SignIn = () => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    const { isLoading, signIn } = useAuth();

    const theme = useTheme();

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        signIn({
            login,
            password,
        });
    };

    return (
        <SignInWrap>
            <form onSubmit={handleSubmit}>
                <h1>Entrar na conta</h1>
                <h2>Apenas contas administrativas</h2>

                <InputText
                    isRequired
                    label="Login"
                    name="username"
                    onChange={setLogin}
                    value={login}
                    placeholder="informe o login"
                    variation="solid"
                    style={{ marginBottom: theme.spacing[10] }}
                />

                <InputText
                    isRequired
                    isPassword
                    label="Senha"
                    name="current-password"
                    onChange={setPassword}
                    value={password}
                    placeholder="informe a senha"
                    variation="solid"
                    style={{ marginBottom: theme.spacing[10] }}
                />

                <Button
                    isFluid
                    variation="secondary"
                    type="submit"
                    isDisabled={isLoading}
                >
                    {isLoading ? 'Carregando...' : 'Entrar'}
                </Button>
            </form>
        </SignInWrap>
    );
};

export default SignIn;
