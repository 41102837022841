import { InputCapsuleWrap, ErrorText } from './styles';

import Skeleton from '../../Skeleton';

import type { InputCapsuleInterface } from './interface';

const InputCapsule = ({
    htmlFor,
    label,
    isLoading = false,
    isRequired,
    children,
    empty,
    variation = 'solid',
    style,
    error
}: InputCapsuleInterface) => (
    <InputCapsuleWrap
        empty={empty || false}
        variation={variation}
        style={style}
        hasError={!!error}
    >
        {label && (
            <label htmlFor={htmlFor}>
                {label} {isRequired && <span>*</span>}
            </label>
        )}
        <div className="input">
            {isLoading ? <Skeleton height="100%" width="100%" /> : children}
        </div>

        {error && <ErrorText className="error">{error}</ErrorText>}
    </InputCapsuleWrap>
);

export default InputCapsule;
