/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import { Button, Card, Grid } from 'components';
import { useLayout } from 'contexts/LayoutContext';
import {  ROUTES_PANEL } from '@constants';
import { useGetAssistantAgentList } from 'services';

import { AssistantAgentForm, AssistantAgentTable } from '../components';

export const PanelAssistantAgentsList = () => {
    const theme = useTheme();

    const { initLayout } = useLayout();

    useEffect(() => {
        initLayout('Lista de Agentes AI', [
            {
                label: 'Agtor Admin',
                link: ROUTES_PANEL.fullPath,
            },
            {
                label: 'Agentes de IA',
                link: ROUTES_PANEL.ASSISTANT_AGENTS.fullPath,
            },
        ]);
    }, []);

    const [isOpenModalRegister, setIsOpenModalRegister] = useState(false);
    const [idRegister, setIdRegister] = useState('');

    const onNewRegister = () => {
        setIdRegister('');
        setIsOpenModalRegister(true);
    };

    const onSelectRegister = (id: string) => {
        setIdRegister(id);
        setIsOpenModalRegister(true);
    };

    const { data, isLoading } = useGetAssistantAgentList();

    return (
        <>
            <Grid style={{ marginTop: theme.spacing[6] }}>
                <Card
                    headerTitle="Lista de Agentes de IA"
                    headerSubTitle="Todos os registros da base"
                    headerToolbar={
                        <Button onClick={onNewRegister}>Novo Registro</Button>
                    }
                >
                    <AssistantAgentTable
                        data={data?.data || []}
                        isLoading={isLoading}
                        onSelectRegister={onSelectRegister}
                    /> 
                </Card>
            </Grid>

            <AssistantAgentForm
                id={idRegister}
                onOpen={setIsOpenModalRegister}
                isOpen={isOpenModalRegister}
            />
        </>
    );
};

