import { useEffect, useMemo, useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import * as Yup from 'yup';

import { Button, Drawer, InputText } from 'components';
import { onValidationSchemaWithErrors } from 'helpers';

import { BenefitSummary } from './components'

import {
    BenefitFieldWrap,
    BenefitFieldLabel,
    BenefitFieldMoreButton,
    BenefitFieldList,
    BenefitFieldForm,
    BenefitFieldFormTitle,
    BenefitFieldFormFooter,
} from './BenefitField.style';

import type { IAPlanBenefit } from '@types';

import type { PlanFormBenefitFieldProps } from './BenefitField.type';

export const PlanFormBenefitField = ({
    items,
    isLoading,
    isOpen,
    onOpen,
    onSave,
}: PlanFormBenefitFieldProps) => {
    const [editRegister, setEditRegister] = useState<
        Partial<IAPlanBenefit> | undefined
    >(undefined);
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [listenErrors, setListenErrors] = useState<boolean>(false);


    const onChangeValue = (
        key: keyof { name: string },
        value: string | number
    ) => {
        const rest: Partial<IAPlanBenefit> = {};

        const newValue = {
            ...editRegister,
            ...rest,
            [key]: value,
        } as {name: string };

        setEditRegister(newValue);
    };

    const onNewRegister = () => {
        onOpen(true);
        setEditRegister(undefined);

    };

    const onEditRegister = (item: Partial<IAPlanBenefit>) => {
        setEditRegister(item);
        onOpen(true);

    };

    const shapeSchema = {
        name: Yup.string().required("O nome é obrigatório."),
    };

    const onSubmitSave = async () => {
        const result = await onValidationSchemaWithErrors(
            shapeSchema,
            editRegister
        );

        if (!result.isValid) {
            setErrors(result.errors);
            setListenErrors(true);

            return;
        }

        if (editRegister) {
            const isUpdateRegister = items?.some(
                (item) => item.id === editRegister.id
            );

            if (isUpdateRegister) {
                const newItems =
                    items?.map((item) =>
                        item.id === editRegister.id ? editRegister : item
                    ) || [];

                onSave(newItems);
            } else {
                onSave([...items, {id: `${items?.length}`, ...editRegister}]);
            }

            onOpen(false);
            setListenErrors(false);
        }
    };

    const onRemoveRegister = () => {
        if (editRegister) {
            const newItems = items?.filter(
                (item) => item.id !== editRegister.id
            );

            onSave(newItems);
            onOpen(false);
            setListenErrors(false);
        }
    };

    useEffect(() => {
        const updateErrors = async () => {
            if (listenErrors) {
                const result = await onValidationSchemaWithErrors(
                    shapeSchema,
                    editRegister
                );

                setErrors(result.errors);
            }
        };

        updateErrors();
    }, [listenErrors, editRegister]);

    const list = useMemo(() => {
        if (isLoading) {
            return <BenefitSummary isLoading onEdit={() => null} />;
        }

        return items?.map((item) => (
            <BenefitSummary
                key={item.name}
                item={item}
                onEdit={() => onEditRegister(item)}
            />
        ));
    }, [items, isLoading]);

    return (
        <BenefitFieldWrap>
            <BenefitFieldLabel>
                Benefícios
            </BenefitFieldLabel>

            <BenefitFieldList>{list}</BenefitFieldList>

            <BenefitFieldMoreButton onClick={onNewRegister}>
                <BiPlus /> Adicionar benefício
            </BenefitFieldMoreButton>

            <Drawer
                isOpen={isOpen}
                onOpen={onOpen}
                position="absolute"
                showCloseButton={false}
            >
                <BenefitFieldForm>
                    <BenefitFieldFormTitle>
                        {editRegister
                            ? "Editar benefício"
                            : "Adicionar benefício"}
                    </BenefitFieldFormTitle>
                    
                    <InputText
                        label="Nome"
                        placeholder="Informe um nome"
                        name="name"
                        isRequired
                        onChange={(newValue) =>
                            onChangeValue('name', newValue)
                        }
                        value={
                            editRegister?.name
                                ? editRegister.name
                                : ''
                        }
                        error={errors?.name}
                    />

                    <BenefitFieldFormFooter>
                        {editRegister && (
                            <Button
                                variation="danger"
                                onClick={onRemoveRegister}
                                style={{ marginRight: 'auto' }}
                            >
                                Remover
                            </Button>
                        )}
                        <Button variation="light" onClick={() => onOpen(false)}>
                            Voltar
                        </Button>
                        <Button onClick={onSubmitSave}>
                           Avançar
                        </Button>
                    </BenefitFieldFormFooter>
                </BenefitFieldForm>
            </Drawer>
        </BenefitFieldWrap>
    );
};
