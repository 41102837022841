export const QUERIES = {
    AUTH: {
        signIn: 'auth-sign-in',
    },
    ACCOUNT: {
        module: 'account',
        form: 'account-form',
        update: 'account-update',
        list: 'account-list',
    },
    ACCOUNT_TAG: {
        form: 'account-tag-form',
        list: 'account-tag-list',
        remove: 'account-tag-remove',
    },
    ASSISTANT_AGENT: {
        module: 'assistant-agent-module',
    },
    DASHBOARD: {
        index: 'dashboard-index',
    },
    PUSH_NOTIFICATION: {
        send: 'push-notification',
    },
    TRACKING: {
        base: "tracking-base"
    },
    PLAN: {
        module: "plan-module"
    },
    GATEWAY_PAYMENT: {
        payment: "gateway-payment-payment-module",
        customer: "gateway-payment-customer-module"
    },
    SUBSCRIPTION_PAYMENT: {
        module: "subscription-payment-module"
    },

};
