import api from './api';

export const PUSH_NOTIFICATION_URL = 'pushs_notifications';

export const sendUniquePushNotification = async (
    accountsIds?: number,
    titleNotifications?: string,
    textNotifications?: string
) => {
    const { data } = await api.post(PUSH_NOTIFICATION_URL, {
        accountsIds: [accountsIds],
        titleNotifications,
        textNotifications,
    });

    return data;
};

export const sendAllPushNotification = async (
    titleNotifications?: string,
    textNotifications?: string
) => {
    const { data } = await api.post(`${PUSH_NOTIFICATION_URL}/all`, {
        titleNotifications,
        textNotifications,
    });

    return data;
};
