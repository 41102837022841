import { useEffect, useState, ReactNode } from 'react';
import { useTheme } from 'styled-components';

import { Card, Grid, Tabs } from 'components';
import { useLayout } from 'contexts/LayoutContext';
import { ROUTES_PANEL } from '@constants';

import { TableWrap } from './styles'
import { GatewayPaymentPaymentTable, GatewayPaymentCustomerTable } from '../components';


const GatewayPayments = () => {
    const theme = useTheme();

    const { initLayout } = useLayout();

    const [tabValue, setTabValue] = useState<string>('users');

    const componentsTabs: Record<string, ReactNode> = {
        users: <GatewayPaymentCustomerTable />,
        payments: <GatewayPaymentPaymentTable />,
    }

    useEffect(() => {
        initLayout('Integração', [
            {
                label: 'Agtor Admin',
                link: ROUTES_PANEL.fullPath,
            },
            {
                label: 'Integração',
                link: ROUTES_PANEL.GATEWAY_PAYMENTS.fullPath,
            },
        ]);
    }, []);

    return (
        <Grid style={{ marginTop: theme.spacing[6] }}>
            <Card
                noBorder
            >
                <Tabs
                    onChange={setTabValue}
                    value={tabValue}
                    items={[
                        { label: 'Usuários', value: 'users' },
                        { label: 'Pagamentos', value: 'payments' },
                    ]}
                />

                <TableWrap>
                    {componentsTabs?.[tabValue]}
                </TableWrap>
            </Card>
        </Grid>
    );
};

export default GatewayPayments;
