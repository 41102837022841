import { ProfileWrap } from './styles';

import type { ProfileInterface } from './interface';

const Profile = ({ image, name }: ProfileInterface) => {
    function getFirstLetterName() {
        return name
            .split(' ')
            .map((splittedName) => splittedName[0])
            .join('');
    }

    return (
        <ProfileWrap>
            <div
                className="box-name"
                title={name}
                style={image ? { backgroundImage: `url(${image})` } : {}}
            >
                {image ? '' : getFirstLetterName()}
            </div>
        </ProfileWrap>
    );
};

export default Profile;
