import styled from 'styled-components';

import backgrondLogin from 'assets/images/background_login.png';

export const AuthWrap = styled.div`
    height: 100vh;
    width: 100vw;

    display: flex;
    flex-direction: row;

    .column-info {
        display: flex;
        flex-direction: column;
        background-image: url(${backgrondLogin});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 100%;

        padding: ${(props) => props.theme.spacing[10]};

        .logo {
            max-height: ${(props) => props.theme.spacing[20]};
            margin-right: auto;
        }
    }
`;
