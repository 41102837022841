/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTheme } from 'styled-components';

import { Button, Card, Grid, Modal, Select, Table } from 'components';
import { onErrorMessage, onSuccessMessage } from 'helpers';
import {
    getAccountsTagsList,
    addTagsToAccounts,
    removeTagsToAccounts,
} from 'services';
import { distanceToNow, sweetAlert } from 'utils';

import { QUERIES } from '@constants';

import type { IAAccountTag } from '@types';
import type {
    AccountTagRemoveMutationInterface,
    CardAccountAccessInterface,
} from './interface';

const CardSetting = ({ data }: CardAccountAccessInterface) => {
    const queryClient = useQueryClient();
    const theme = useTheme();

    const titleMessages = 'Tag de Usuário';

    const [accountsTagsIds, setAccountsTagsIds] = useState('');

    const [isOpenModal, setIsOpenModal] = useState(false);

    const { mutate: mutateRemoveTag }: AccountTagRemoveMutationInterface =
        useMutation((id) => removeTagsToAccounts(data?.id, id), {
            mutationKey: QUERIES.ACCOUNT_TAG.remove,
            onSuccess: () => {
                onSuccessMessage(
                    titleMessages,
                    'Registro removido com sucesso!'
                );

                queryClient.refetchQueries(QUERIES.ACCOUNT.module);
            },
            onError: (err) => {
                onErrorMessage(titleMessages, err);
            },
        });

    function onRemoveRegisterRequest(accountsTagsIdRemoved: number) {
        sweetAlert
            .fire({
                title: 'Você tem certeza?',
                text: 'Se você confirmar este registro será removido e está ação não poderá ser revertida!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: theme.colors.base.primary,
                cancelButtonColor: theme.colors.base.danger,
                confirmButtonText: 'Sim, remover registro!',
                cancelButtonText: 'Não, cliquei errado!',
            })
            .then((result) => {
                if (result.isConfirmed) {
                    mutateRemoveTag(accountsTagsIdRemoved);
                }
            });
    }

    const tagOption = useCallback(
        ({ id }) => (
            <Button
                model="base"
                variation="danger"
                onClick={() => onRemoveRegisterRequest(id)}
                isSquare
                style={{ marginLeft: theme.spacing[2] }}
            >
                <AiOutlineDelete size={theme.fontSizes[5]} />
            </Button>
        ),
        []
    );

    const { data: dataTags, isLoading: isLoadingTags } = useQuery(
        QUERIES.ACCOUNT_TAG.list,
        () => getAccountsTagsList()
    );

    const { mutate: mutateNewTag, isLoading: isLoadingNewTag } = useMutation(
        () => addTagsToAccounts(data.id, { accountsTagsIds }),
        {
            mutationKey: [QUERIES.ACCOUNT.module],
            onSuccess: () => {
                queryClient.refetchQueries(QUERIES.ACCOUNT.module);

                onSuccessMessage(
                    titleMessages,
                    'Sucesso ao adicionar a tag de usuário!'
                );

                setIsOpenModal(false);
            },
            onError: (err) => onErrorMessage(titleMessages, err),
        }
    );

    return (
        <Grid>
            <Card
                headerTitle="Tags da Conta"
                headerSubTitle="Atribuídas a este usuário"
                headerToolbar={
                    <Button
                        isLoading={isLoadingTags}
                        onClick={() => setIsOpenModal(true)}
                    >
                        Adicionar Tags
                    </Button>
                }
            >
                <div
                    style={{ width: '100%', height: '100%', overflowY: 'auto' }}
                >
                    <Table
                        columns={[
                            {
                                dataField: 'hasAccountsTags',
                                text: 'Nome',
                                style: {
                                    width: '100%',
                                },
                                formatter: (value) => value.name,
                            },
                            {
                                dataField: 'createdAt',
                                text: 'Criado em',
                                style: { minWidth: '14rem' },
                                formatter: (value) => distanceToNow(value),
                                sort: true,
                            },
                            {
                                dataField: 'updatedAt',
                                text: 'Atualiado em',
                                style: { minWidth: '14rem' },
                                formatter: (value) => distanceToNow(value),
                                sort: true,
                            },
                            {
                                dataField: 'id',
                                text: 'Opções',
                                formatter: (_value, _index, row) =>
                                    tagOption(row),
                            },
                        ]}
                        data={data?.accountsTags}
                    />
                </div>
            </Card>
            <Modal
                title="Adicionar Nova Tag"
                subTitle="Para o Usuário"
                isOpen={isOpenModal}
                onCloseModal={() => setIsOpenModal(false)}
                footer={
                    <Button
                        isLoading={isLoadingNewTag}
                        onClick={() => mutateNewTag()}
                        variation="secondary"
                    >
                        Adicionar Tag
                    </Button>
                }
            >
                <Select
                    label="Tags"
                    isRequired
                    name="new-tag"
                    onChange={setAccountsTagsIds}
                    placeholder="Selecione Uma Tag"
                    value={accountsTagsIds}
                    options={dataTags?.map((tag: IAAccountTag) => ({
                        label: tag.name,
                        value: tag.id,
                    }))}
                />
            </Modal>
        </Grid>
    );
};

export default CardSetting;
