import { useCallback, useMemo } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { useMutation, useQueryClient } from 'react-query';
import { useTheme } from 'styled-components';

import { QUERIES } from '@constants';

import { BarChart,Button,  Badge, Card, Grid, Table } from 'components';
import { onErrorMessage, onSuccessMessage } from 'helpers';
import {
    removeTracking,
} from 'services';

import { sweetAlert, distanceToNow } from 'utils';

import type { CardAccountAnalyticsInterface } from './interface';

const CardAnalytics = ({ data, isLoading }: CardAccountAnalyticsInterface) => {
    const theme = useTheme();
    const titleMessages = "Tracking"
    const queryClient = useQueryClient();

    const dataChartsTrackingsYear = useMemo(() => {
        const trackingChartsYear = data?.charts?.trackingChartsYear;

        if (isLoading ||
            (!trackingChartsYear && !Array.isArray(trackingChartsYear)) ||
            trackingChartsYear?.length < 1
        ) {
            return [
                {
                    label: 'Atividades do Usuário',
                    backgroundColor: theme.colors.base.primary,
                    data: [
                        {
                            label:
                                trackingChartsYear?.length < 1
                                    ? 'XX/XX'
                                    : 'NA/NA',
                            value: 0,
                        },
                    ],
                },
            ];
        }

        return [
            {
                label: 'Atividades do Usuário',
                backgroundColor: theme.colors.base.primary,
                data: trackingChartsYear?.map(({ count, formatted }: any) => ({
                    label: formatted,
                    value: count,
                })),
            },
        ];
    }, [data, isLoading]);

    const dataChartsTrackingsMonth = useMemo(() => {
        const trackingChartsMonth = data?.charts?.trackingChartsMonth;

        if (
            isLoading ||
            (!trackingChartsMonth && !Array.isArray(trackingChartsMonth)) ||
            trackingChartsMonth?.length < 1
        ) {
            return [
                {
                    label: 'Atividades do Usuário',
                    backgroundColor: theme.colors.base.primary,
                    data: [
                        {
                            label:
                                trackingChartsMonth?.length < 1
                                    ? 'XX/XX'
                                    : 'NA/NA',
                            value: 0,
                        },
                    ],
                },
            ];
        }

        return [
            {
                label: 'Atividades do Usuário',
                backgroundColor: theme.colors.base.primary,
                data: trackingChartsMonth?.map(({ count, formatted }: any) => ({
                    label: formatted,
                    value: count,
                })),
            },
        ];
    }, [data, isLoading]);

    const columnApplication = useCallback(
        (value: string) => <Badge>{value}</Badge>,
        []
    );


    const { mutate: mutateRemoveTag } =
        useMutation((id: number) => removeTracking(id), {
            mutationKey: QUERIES.ACCOUNT.module,
            onSuccess: () => {
                onSuccessMessage(
                    titleMessages,
                    'Registro removido com sucesso!'
                );

                queryClient.refetchQueries(QUERIES.ACCOUNT.module);
            },
            onError: (err) => {
                onErrorMessage(titleMessages, err);
            },
        });

    function onRemoveRegisterRequest(accountsTagsIdRemoved: number) {
        sweetAlert
            .fire({
                title: 'Você tem certeza?',
                text: 'Se você confirmar este registro será removido e está ação não poderá ser revertida!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: theme.colors.base.primary,
                cancelButtonColor: theme.colors.base.danger,
                confirmButtonText: 'Sim, remover registro!',
                cancelButtonText: 'Não, cliquei errado!',
            })
            .then((result) => {
                if (result.isConfirmed) {
                    mutateRemoveTag(accountsTagsIdRemoved);
                }
            });
    }

    const trackingOption = useCallback(
        ({ id }) => (
            <Button
                model="base"
                variation="danger"
                onClick={() => onRemoveRegisterRequest(id)}
                isSquare
                style={{ marginLeft: theme.spacing[2] }}
            >
                <AiOutlineDelete size={theme.fontSizes[5]} />
            </Button>
        ),
        []
    );


    return (
        <div>
            <Grid numberColumns={[2, 2, 2, 2, 1]} gridGap={theme.spacing[8]}>
                <Card
                    headerTitle="Gráfico de Atividades do Último Ano"
                    headerSubTitle="Mês à mês"
                >
                    <BarChart
                        height={250}
                        data={dataChartsTrackingsYear}
                        elementType="line"
                    />
                </Card>

                <Card
                    headerTitle="Gráfico de Atividades do Último Mês"
                    headerSubTitle="Dia à dia"
                >
                    <BarChart
                        height={250}
                        data={dataChartsTrackingsMonth}
                        elementType="line"
                    />
                </Card>
            </Grid>

            <Grid style={{ marginTop: theme.spacing[8] }}>
                <Card
                    headerTitle="Últimos 50 eventos do usuário"
                    headerSubTitle="Atribuídas a este usuário"
                >
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            overflowY: 'auto',
                        }}
                    >
                        <Table
                            columns={[
                                {
                                    dataField: 'eventName',
                                    text: 'Nome',
                                    style: {
                                        width: '100%',
                                        minWidth: '14rem',
                                    },
                                },
                                {
                                    dataField: 'application',
                                    text: 'Aplicação',
                                    style: {
                                        width: '100%',
                                    },
                                    formatter: (value) =>
                                        columnApplication(value),
                                },
                                {
                                    dataField: 'createdAt',
                                    text: 'Criado em',
                                    style: { minWidth: '14rem' },
                                    formatter: (value) => distanceToNow(value),
                                    sort: true,
                                },
                                {
                                    dataField: 'id',
                                    text: 'Opções',
                                    formatter: (_value, _index, row) =>
                                    trackingOption(row),
                                },
                            ]}
                            data={data?.lists?.trackings}
                        />
                    </div>
                </Card>
            </Grid>
        </div>
    );
};

export default CardAnalytics;
