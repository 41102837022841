import { FormEvent, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTheme } from 'styled-components';
import * as Yup from 'yup';

import { QUERIES } from '@constants';
import { Button, InputText, Modal } from 'components';
import { onValidationSchema, onErrorMessage, onSuccessMessage } from 'helpers';
import {
    createAccountsTags,
    getAccountsTagsDetail,
    updateAccountsTags,
} from 'services';

import type { AccountsTagsFormInterface } from './interface';

const AccountsTagsForm = ({
    id,
    isOpen,
    onOpen,
}: AccountsTagsFormInterface) => {
    const theme = useTheme();
    const queryClient = useQueryClient();

    const titleMessages = 'Tags do Usuário';

    const [name, setName] = useState('');
    const [slug, setSlug] = useState('');

    useEffect(() => {
        if (id === '') {
            setName('');
            setSlug('');
        }
    }, [id]);

    const { isLoading: isLoadingGet } = useQuery(
        [QUERIES.ACCOUNT_TAG.form, id],
        () => getAccountsTagsDetail(id),
        {
            enabled: id !== '',
            onSuccess: (data) => {
                setName(data?.name);
                setSlug(data?.slug);
            },
        }
    );

    const { isLoading: isLoadingCreate, mutate: mutateCreate } = useMutation(
        () =>
            createAccountsTags({
                name,
                slug,
            }),
        {
            mutationKey: QUERIES.ACCOUNT_TAG.form,
            onSuccess: () => {
                onSuccessMessage(
                    titleMessages,
                    'Sucesso ao criar novo registro!'
                );

                queryClient.refetchQueries(QUERIES.ACCOUNT_TAG.list);

                onOpen(false);
            },
            onError: (err) => onErrorMessage(titleMessages, err),
        }
    );

    const { isLoading: isLoadingUpdate, mutate: mutateUpdate } = useMutation(
        () =>
            updateAccountsTags(id, {
                name,
                slug,
            }),
        {
            mutationKey: QUERIES.ACCOUNT_TAG.form,
            onSuccess: () => {
                onSuccessMessage(
                    titleMessages,
                    'Sucesso ao atualizar o registro!'
                );

                queryClient.refetchQueries(QUERIES.ACCOUNT_TAG.list);
                queryClient.refetchQueries([QUERIES.ACCOUNT_TAG.form, id]);

                onOpen(false);
            },
            onError: (err) => {
                onErrorMessage(titleMessages, err);
            },
        }
    );

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        const data = {
            name,
            slug,
        };

        const shapeSchema = {
            slug: Yup.string().nullable().required('O slug é obrigatório.'),
            name: Yup.string().nullable().required('O nome é obrigatório.'),
        };

        const { success } = await onValidationSchema(shapeSchema, data);

        if (success) {
            if (!id) {
                mutateCreate();
            } else {
                mutateUpdate();
            }
        }
    };

    const isLoading = isLoadingGet || isLoadingCreate || isLoadingUpdate;

    return (
        <form onSubmit={onSubmit}>
            <Modal
                title="Tags do Usuário"
                subTitle={id ? 'Editar Registro' : 'Novo Registro'}
                isOpen={isOpen}
                onCloseModal={() => onOpen(false)}
                footer={
                    <div>
                        <Button
                            type="button"
                            style={{ marginRight: theme.spacing[3] }}
                            model="light"
                            onClick={() => onOpen(false)}
                        >
                            Fechar
                        </Button>
                        <Button
                            type="submit"
                            isDisabled={isLoading}
                            isLoading={isLoading}
                        >
                            Salvar Tag
                        </Button>
                    </div>
                }
            >
                <InputText
                    value={name}
                    onChange={setName}
                    label="Nome"
                    name="name"
                    placeholder="Informe um nome"
                    isLoading={isLoading}
                    style={{ marginBottom: theme.spacing[8] }}
                />

                <InputText
                    value={slug}
                    onChange={setSlug}
                    label="Slug"
                    name="slug"
                    placeholder="Informe um slug"
                    isLoading={isLoading}
                />
            </Modal>
        </form>
    );
};

export default AccountsTagsForm;
