import { ContentWrap } from './styles';

const Content = () => (
    <ContentWrap>
        <h3>Seja bem-vindo!</h3>

        <p>
            A plataforma dinâmica. que auxilia em todo o processo administrativo
            de propriedades rurais. Atendemos todas as fases do ciclo produtivo
            e operational. Números de notas, tamanhos de talhões, insumos
            utilizados, isso e muito mais.
        </p>

        <p>
            <strong>Informação que gera resultado!</strong>
        </p>
    </ContentWrap>
);

export default Content;
